import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import styled from 'styled-components';
import moment from 'moment';
import { Table, Button, message } from 'antd';
import BillPreparingModifyDialog from './BillPreparingModifyDialog';
import BillPreparingConfirmDialog from './BillPreparingConfirmDialog';
import key from '../../config/key';
import { numberFormat } from '../Utils/Format';
import { getToken } from '../../utils/setting';

class PvBillPreparing extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dataSource: [],
      sellPrice: null,
      showModify: false,
      showConfirm: false,
      record: {},
    };
  }

  componentDidMount() {
    this.loadData();
    this.loadSellPrice();
  }

  loadData() {
    return axios
      .get(`/api/billPreparing/all/${this.props.id}`, { headers: { Authorization: getToken() } })
      .then((res) => {
        if (res.data.status) {
          this.setState({ dataSource: res.data.result.billsPreparing });
        } else {
          message.error(res.data.msg);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  loadSellPrice() {
    return axios
      .get(`/api/billPreparing/sellPrice/${this.props.id}`, { headers: { Authorization: getToken() } })
      .then((res) => {
        if (res.data.status) {
          this.setState({ sellPrice: res.data.result.sellPrice });
        } else {
          message.error(res.data.msg);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  handleOpenModify = (record) => {
    this.setState({ showModify: true, record });
  };

  handleModify = (v) => {
    return axios
      .post(
        `/api/billPreparing/modify`,
        {
          id: this.state.record.id,
          pvId: this.props.id,
          rangeBegin: v.range[0].format('YYYY-MM-DD'),
          rangeEnd: v.range[1].format('YYYY-MM-DD'),
          totalPower: v.totalPower,
          powerAmount: v.powerAmount,
          deductAmount: v.deductAmount,
          salesTax: Math.round((Math.round(v.totalPower * v.sellPrice) + v.deductAmount) * 0.05),
          incomeWithTax: v.incomeWithTax,
          powerRent: v.powerRent,
          makeupRent: v.makeupRent,
          meterRentCost: v.meterRentCost,
        },
        { headers: { Authorization: getToken() } }
      )
      .then((res) => {
        if (res.data.status) {
          this.setState({ showModify: false });
          message.info('修改電費單成功');
          this.loadData();
        } else {
          message.error(res.data.msg);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  handleDistribute = (id) => {
    return axios
      .post(
        `/api/billPreparing/distribute`,
        {
          id,
        },
        { headers: { Authorization: getToken() } }
      )
      .then((res) => {
        if (res.data.status) {
          this.loadData();
        } else {
          message.error(res.data.msg);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  handleCancelDistribute = (id) => {
    return axios
      .post(
        `/api/billPreparing/updateStatus`,
        {
          id,
          status: null,
        },
        { headers: { Authorization: getToken() } }
      )
      .then((res) => {
        if (res.data.status) {
          this.loadData();
        } else {
          message.error(res.data.msg);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  handleReport = (id) => {
    //TODO: show loading report
    axios
      .get(key.reportURL + '/api/v1/report/getPdf', {
        //headers: { 'Authorization': this.props.auth.token },
        responseType: 'blob',
        params: {
          page: 'BillPreparingReport/' + id,
        },
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('target', '_blank');
        document.body.appendChild(link);
        link.click();
      });
  };

  handleReview = (id) => {
    return axios
      .post(
        `/api/billPreparing/updateStatus`,
        {
          id,
          status: 1,
        },
        { headers: { Authorization: getToken() } }
      )
      .then((res) => {
        if (res.data.status) {
          this.loadData();
        } else {
          message.error(res.data.msg);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  handleCancelReview = (id) => {
    return axios
      .post(
        `/api/billPreparing/updateStatus`,
        {
          id,
          status: 0,
        },
        { headers: { Authorization: getToken() } }
      )
      .then((res) => {
        if (res.data.status) {
          this.loadData();
        } else {
          message.error(res.data.msg);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  handleOpenConfirm = (record) => {
    this.setState({ showConfirm: true, record });
  };

  handleConfirm = (v) => {
    return axios
      .post(
        `/api/billPreparing/confirm`,
        {
          id: this.state.record.id,
          pvId: this.props.id,
          file: this.state.record.file,
          rangeBegin: v.range[0].format('YYYY-MM-DD'),
          rangeEnd: v.range[1].format('YYYY-MM-DD'),
          totalPower: v.totalPower,
          incomeWithTax: v.incomeWithTax,
          meterRentCost: v.meterRentCost,
          profit: v.profit,
          rentCostFixed: v.rentCostFixed,
          rentCostFloat: v.rentCostFloat,
          operateCostFixed: v.operateCostFixed,
          operateCostFloat: v.operateCostFloat,
          serviceCostFloat: v.serviceCostFloat,
          insuranceCost: v.insuranceCost,
          loanCost: v.loanCost,
          loanFeeCost: v.loanFeeCost,
          inTrust: v.inTrust,
        },
        { headers: { Authorization: getToken() } }
      )
      .then((res) => {
        if (res.data.status) {
          this.setState({ showConfirm: false });
          message.info('建立電費分潤成功');
          this.loadData();
        } else {
          message.error(res.data.msg);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  render() {
    const padding = 32;
    const fontSize = 14;

    const columns = [
      {
        title: '檔案',
        dataIndex: 'file',
        key: 'file',
        width: padding + fontSize * 4,
        align: 'center',
        fixed: 'left',
        render: (text, record) => {
          if (record.file) {
            return (
              <Link to={`/api/upload/file/${record.file}`} target="_blank">
                查看
              </Link>
            );
          } else {
            return null;
          }
        },
      },
      {
        title: '計費起始日',
        dataIndex: 'rangeBegin',
        width: padding + fontSize * 6,
        key: 'rangeBillBegin',
        fixed: 'left',
      },
      {
        title: '計費結束日',
        dataIndex: 'rangeEnd',
        width: padding + fontSize * 6,
        key: 'rangeBillEnd',
        fixed: 'left',
        render: (v) => moment(v).subtract(1, 'days').format('YYYY-MM-DD'),
      },
      {
        title: '抄表起始日',
        dataIndex: 'rangeBegin',
        width: padding + fontSize * 6,
        key: 'rangeBegin',
      },
      {
        title: '抄表結束日',
        dataIndex: 'rangeEnd',
        width: padding + fontSize * 6,
        key: 'rangeEnd',
      },
      {
        title: '發電度數',
        dataIndex: 'totalPower',
        width: padding + fontSize * 6,
        key: 'totalPower',
        align: 'right',
        render: (v) => numberFormat({ value: v, postfix: '度' }),
      },
      {
        title: '發電金額',
        dataIndex: 'powerAmount',
        width: padding + fontSize * 5,
        key: 'powerAmount',
        align: 'right',
        render: (v) => numberFormat({ value: v, postfix: '元' }),
      },
      {
        title: '補付(扣)電費',
        dataIndex: 'deductPowerAmount',
        width: padding + fontSize * 6,
        key: 'deductAmount',
        align: 'right',
        render: (v) => numberFormat({ value: v, postfix: '元' }),
      },
      {
        title: '營業稅',
        dataIndex: 'salesTax',
        width: padding + fontSize * 6,
        key: 'salesTax',
        align: 'right',
        render: (v) => numberFormat({ value: v, postfix: '元' }),
      },
      {
        title: '總電費(含稅)',
        dataIndex: 'incomeWithTax',
        width: padding + fontSize * 6,
        key: 'incomeWithTax',
        align: 'right',
        render: (v) => numberFormat({ value: v, postfix: '元' }),
      },
      {
        title: '本期電表租費',
        dataIndex: 'powerRent',
        width: padding + fontSize * 6,
        key: 'powerRent',
        align: 'right',
        render: (v) => numberFormat({ value: v, postfix: '元' }),
      },
      {
        title: '電表租費收(退)',
        dataIndex: 'makeupPowerRent',
        width: padding + fontSize * 7,
        key: 'makeupRent',
        align: 'right',
        render: (v) => numberFormat({ value: v, postfix: '元' }),
      },
      {
        title: '電表租費',
        dataIndex: 'meterRentCost',
        width: padding + fontSize * 6,
        key: 'meterRentCost',
        align: 'right',
        render: (v) => numberFormat({ value: v, postfix: '元' }),
      },
      {
        title: '收益權分潤',
        dataIndex: 'profit',
        width: padding + fontSize * 6,
        key: 'profit',
        align: 'right',
        render: (v) => numberFormat({ value: v, postfix: '元' }),
      },
      {
        title: '租金(固定)',
        dataIndex: 'rentCostFixed',
        width: padding + fontSize * 6,
        key: 'rentCostFixed',
        align: 'right',
        render: (v) => numberFormat({ value: v, postfix: '元' }),
      },
      {
        title: '租金(浮動)',
        dataIndex: 'rentCostFloat',
        width: padding + fontSize * 6,
        key: 'rentCostFloat',
        align: 'right',
        render: (v) => numberFormat({ value: v, postfix: '元' }),
      },
      {
        title: '營運費(固定)',
        dataIndex: 'operateCostFixed',
        width: padding + fontSize * 6,
        key: 'operateCostFixed',
        align: 'right',
        render: (v) => numberFormat({ value: v, postfix: '元' }),
      },
      {
        title: '營運費(浮動)',
        dataIndex: 'operateCostFloat',
        width: padding + fontSize * 6,
        key: 'operateCostFloat',
        align: 'right',
        render: (v) => numberFormat({ value: v, postfix: '元' }),
      },
      {
        title: '服務費',
        dataIndex: 'serviceCostFloat',
        width: padding + fontSize * 6,
        key: 'serviceCostFloat',
        align: 'right',
        render: (v) => numberFormat({ value: v, postfix: '元' }),
      },
      {
        title: '保險費',
        dataIndex: 'insuranceCost',
        width: padding + fontSize * 6,
        key: 'insuranceCost',
        align: 'right',
        render: (v) => numberFormat({ value: v, postfix: '元' }),
      },
      {
        title: '貸款費',
        dataIndex: 'loanCost',
        width: padding + fontSize * 6,
        key: 'loanCost',
        align: 'right',
        render: (v) => numberFormat({ value: v, postfix: '元' }),
      },
      {
        title: '貸款手續費',
        dataIndex: 'loanFeeCost',
        width: padding + fontSize * 6,
        key: 'loanFeeCost',
        align: 'right',
        render: (v) => numberFormat({ value: v, postfix: '元' }),
      },
      {
        title: '狀態',
        dataIndex: 'status',
        width: padding + fontSize * 4,
        key: 'status',
        align: 'left',
        fixed: 'right',
        render: (v) => {
          switch (v) {
            case null:
              return '未結算';
            case 0:
              return '待審核';
            case 1:
              return '審核中';
            case 2:
              return '已核准';
            default:
              return '未知';
          }
        },
      },
      {
        title: '操作',
        width: padding + fontSize * 15,
        key: 'action',
        fixed: 'right',
        render: (text, record) => {
          let operations = [];
          switch (record.status) {
            case null:
              operations.push(
                <Button
                  key={1}
                  onClick={() => {
                    this.handleOpenModify(record);
                  }}
                >
                  {'修改'}
                </Button>
              );
              operations.push(
                <Button
                  key={2}
                  onClick={() => {
                    this.handleDistribute(record.id);
                  }}
                >
                  {'結算>'}
                </Button>
              );
              break;
            case 0:
              operations.push(
                <Button
                  key={1}
                  onClick={() => {
                    this.handleCancelDistribute(record.id);
                  }}
                >
                  {'<返回'}
                </Button>
              );
              operations.push(
                <Button
                  key={2}
                  onClick={() => {
                    this.handleReport(record.id);
                  }}
                >
                  {'報表'}
                </Button>
              );
              operations.push(
                <Button
                  key={3}
                  onClick={() => {
                    this.handleReview(record.id);
                  }}
                >
                  {'審核>'}
                </Button>
              );
              break;
            case 1:
              operations.push(
                <Button
                  key={1}
                  onClick={() => {
                    this.handleCancelReview(record.id);
                  }}
                >
                  {'<返回'}
                </Button>
              );
              operations.push(
                <Button
                  key={2}
                  onClick={() => {
                    this.handleOpenConfirm(record);
                  }}
                >
                  {'審核完成>'}
                </Button>
              );
              break;
            default:
              break;
          }
          return <Operations>{operations}</Operations>;
        },
      },
    ];

    // 先將「操作」欄位功能隱藏，因此相關功能在「電費結算」已經有批次功能
    delete columns[columns.length - 1];

    const totalWidth = columns.reduce((total, elem) => {
      return total + elem.width;
    }, 0);
    return (
      <div>
        <Table
          dataSource={this.state.dataSource}
          columns={columns}
          defaultPageSize={5}
          scroll={{ x: totalWidth }}
        />
        {this.state.showModify ? (
          <BillPreparingModifyDialog
            sellPrice={this.state.sellPrice}
            data={this.state.record}
            onModify={this.handleModify}
            onHide={() => this.setState({ showModify: false })}
          />
        ) : null}
        {this.state.showConfirm ? (
          <BillPreparingConfirmDialog
            data={this.state.record}
            onConfirm={this.handleConfirm}
            onHide={() => this.setState({ showConfirm: false })}
          />
        ) : null}
      </div>
    );
  }
}

const Operations = styled.div`
  display: flex;
`;

export default PvBillPreparing;
