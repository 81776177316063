import React, { Component } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import moment from 'moment';
import xlsx from 'xlsx';
import decayList from '../Definition/DecayList';
import valueToName from '../Definition/ValueToName';
import { numberFormat, percentFormat } from '../Utils/Format';
import { numberSort, stringSort } from '../Utils/Sort';
import { getPagination, setPagination } from '../Utils/Cache';
import { Link } from 'react-router-dom';
import { Layout, Table, Card, Modal, Input, Button, Icon, Row, Col, message } from 'antd';
import { getToken } from '../../utils/setting';
const { Header, Content } = Layout;

class PowerCompensation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dataSource: [],
      pagination: getPagination(),
    };
  }

  componentDidMount() {
    this.loadData();
  }

  loadData() {
    return axios
      .get(`/api/powerCompensation/list`, { headers: { Authorization: getToken() } })
      .then((res) => {
        if (res.data.status) {
          this.setState({ dataSource: res.data.result.rows });
        }
      })
      .catch((error) => {
        Modal.error({
          title: '錯誤訊息',
          content: error.response.data.msg || '很抱歉, 系統發生錯誤, 請稍後重試或聯絡系統管理員',
        });
      });
  }

  handleSubmit() {
    return axios
      .post(`/api/powerCompensation/settlement`, {}, { headers: { Authorization: getToken() } })
      .then((res) => {
        if (res.data.status) {
          this.loadData();
          Modal.success({
            title: '結算完成',
            content: res.data.msg,
          });
        } else {
          Modal.warning({
            title: '結算失敗',
            content: res.data.msg,
          });
        }
      })
      .catch((error) => {
        Modal.error({
          title: '錯誤訊息',
          content: error.response.data.msg || '很抱歉, 系統發生錯誤, 請稍後重試或聯絡系統管理員',
        });
      });
  }

  handleReSettlement = (data) => {
    return axios
      .post(
        `/api/powerCompensation/updateSettlemented`,
        { pvId: data.pvId, year: data.year, settlemented: false },
        { headers: { Authorization: getToken() } }
      )
      .then((res) => {
        if (res.data.status) {
          this.loadData();
        } else {
          message.error(res.data.msg);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  handleCancelReSettlement = (data) => {
    return axios
      .post(
        `/api/powerCompensation/updateSettlemented`,
        { pvId: data.pvId, year: data.year, settlemented: true },
        { headers: { Authorization: getToken() } }
      )
      .then((res) => {
        if (res.data.status) {
          this.loadData();
        } else {
          message.error(res.data.msg);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  getColumeProps = (columnName, dataIndex, sortMethod, sortField, sortOrder, searchable, filters) => {
    let props = {
      title: columnName,
      dataIndex: dataIndex,
      key: dataIndex,
      sorter: (a, b) => sortMethod(a[dataIndex], b[dataIndex]),
      sortOrder: sortField === dataIndex ? sortOrder : false,
    };

    if (searchable) {
      props = Object.assign(props, this.getColumnSearchProps(columnName, dataIndex, filters || {}));
    }
    return props;
  };

  getColumnSearchProps = (columnName, dataIndex, filters) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`搜尋 ${columnName}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          搜尋
        </Button>
        <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          重置
        </Button>
      </div>
    ),
    filterIcon: (filtered) => <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : false,
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    filteredValue: filters[dataIndex],
  });

  onChange = (pagination, filters, sorter) => {
    const paginationInfo = {
      current: pagination.current,
      sortOrder: sorter.order,
      sortField: sorter.field,
      filters: filters,
    };
    setPagination(paginationInfo);
    this.setState({ pagination: paginationInfo });
  };

  handleSearch = (selectedKeys, confirm) => {
    confirm();
  };

  handleReset = (clearFilters) => {
    clearFilters();
  };

  handleExport(value) {
    let dataRows = this.state.dataSource.filter((d) => d.settlementMonth === value);
    if (dataRows && dataRows.length > 0) {
      dataRows = dataRows.sort(function (a, b) {
        return a.pvId + a.year.toString() > b.pvId + b.year.toString() ? 1 : -1;
      });
      let array = [
        [
          '案場',
          '索拉代碼',
          '專案代碼',
          '裝置容量',
          '躉售電價(未稅)',
          '投資金額',
          '年度',
          '起始日',
          '結束日',
          '衰減率',
          '保證發電量(首年每KW)',
          '天數',
          '實際發電總量(監控 + 電費單)',
          '保證發電總量(含衰減率)',
          '台電補付(扣)金額',
          '達成率',
          '應補償金額',
          '固定營運費率',
          '超額獎勵',
        ],
      ];
      dataRows.forEach((d) => {
        array.push([
          d.name,
          d.pvId,
          d.projectCode,
          d.capacity,
          d.sellPrice,
          d.ownedCapital,
          d.year,
          d.sdate,
          d.edate,
          d.decay,
          d.annualAmount,
          d.count,
          Number(d.meter_value),
          Number(d.annualpower),
          d.deductAmount,
          Number(d.achievingrate),
          d.profit,
          d.operationRateByCapital,
          d.reward,
        ]);
      });
      let sheet = xlsx.utils.aoa_to_sheet(array);
      sheet['!cols'] = [
        { wch: 33 },
        { wch: 9 },
        { wch: 9 },
        { wch: 9 },
        { wch: 14 },
        { wch: 9 },
        { wch: 5 },
        { wch: 10 },
        { wch: 10 },
        { wch: 7 },
        { wch: 21 },
        { wch: 5 },
        { wch: 26 },
        { wch: 22 },
        { wch: 18 },
        { wch: 7 },
        { wch: 11 },
        { wch: 13 },
        { wch: 9 },
      ];
      // 構造workBook
      let workBook = {
        SheetNames: ['保發補償分析報表'],
        Sheets: {
          保發補償分析報表: sheet,
        },
      };
      // 將workBook寫入檔案
      xlsx.writeFile(workBook, value + '保發補償分析報表_' + moment().format('YYYYMMDD') + '.xlsx');
    } else {
      message.error('查無資料');
    }
  }

  render() {
    const { sortField, sortOrder, filters } = this.state.pagination;

    const padding = 32;
    const fontSize = 14;
    const columns = [
      {
        ...this.getColumeProps('電廠名稱', 'name', stringSort, sortField, sortOrder, true, filters),
        width: padding + fontSize * 16,
        fixed: 'left',
      },
      {
        ...this.getColumeProps('索拉代碼', 'pvId', stringSort, sortField, sortOrder, true, filters),
        width: padding + fontSize * 8,
      },
      {
        ...this.getColumeProps('專案代碼', 'projectCode', stringSort, sortField, sortOrder, true, filters),
        width: padding + fontSize * 8,
      },
      {
        ...this.getColumeProps('裝置容量', 'capacity', numberSort, sortField, sortOrder),
        width: padding + fontSize * 6,
        align: 'right',
        render: (v) => numberFormat({ value: v, postfix: 'kWp' }),
      },
      {
        ...this.getColumeProps('售電價格', 'sellPrice', numberSort, sortField, sortOrder),
        width: padding + fontSize * 6,
        align: 'right',
        render: (v) => numberFormat({ value: v, postfix: '元' }),
      },
      {
        ...this.getColumeProps('發電衰減率', 'decay', numberSort, sortField, sortOrder),
        width: padding + fontSize * 7,
        align: 'right',
        render: (v) => valueToName(decayList, v),
      },
      {
        ...this.getColumeProps('保證發電量', 'annualAmount', numberSort, sortField, sortOrder),
        width: padding + fontSize * 8,
        align: 'right',
        render: (v) => numberFormat({ value: v, postfix: 'kWp' }),
      },
      {
        ...this.getColumeProps('年度', 'year', numberSort, sortField, sortOrder),
        width: padding + fontSize * 4,
        align: 'right',
        render: (v) => numberFormat({ value: v, postfix: '年' }),
      },
      {
        ...this.getColumeProps('起始日', 'sdate', stringSort, sortField, sortOrder),
        width: padding + fontSize * 6,
        align: 'right',
      },
      {
        ...this.getColumeProps('結束日', 'edate', stringSort, sortField, sortOrder),
        width: padding + fontSize * 6,
        align: 'right',
      },
      {
        ...this.getColumeProps('天數', 'count', numberSort, sortField, sortOrder),
        width: padding + fontSize * 4,
        align: 'right',
        render: (v) => numberFormat({ value: v, postfix: '天' }),
      },
      {
        ...this.getColumeProps('實際發電總量', 'meter_value', numberSort, sortField, sortOrder),
        width: padding + fontSize * 8,
        align: 'right',
        render: (v) => numberFormat({ value: v, postfix: '度' }),
      },
      {
        ...this.getColumeProps('保證發電總量', 'annualpower', numberSort, sortField, sortOrder),
        width: padding + fontSize * 8,
        align: 'right',
        render: (v) => numberFormat({ value: v, postfix: '度' }),
      },
      {
        ...this.getColumeProps('台電補付(扣)金額', 'deductAmount', numberSort, sortField, sortOrder),
        width: padding + fontSize * 10,
        align: 'right',
        render: (v) => numberFormat({ value: v, postfix: '元' }),
      },
      {
        ...this.getColumeProps('達成率', 'achievingrate', numberSort, sortField, sortOrder),
        width: padding + fontSize * 6,
        align: 'right',
        render: (v) => percentFormat(v),
      },
      {
        ...this.getColumeProps('保發金額', 'profit', numberSort, sortField, sortOrder),
        width: padding + fontSize * 6,
        align: 'right',
        render: (v) => numberFormat({ value: v, postfix: '元' }),
      },
      {
        ...this.getColumeProps('超額獎勵', 'reward', numberSort, sortField, sortOrder),
        width: padding + fontSize * 6,
        align: 'right',
        render: (v) => numberFormat({ value: v, postfix: '元' }),
      },
      {
        ...this.getColumeProps('結算月份', 'settlementMonth', stringSort, sortField, sortOrder),
        width: padding + fontSize * 6,
        fixed: 'right',
        render: (value, record) => {
          if (value) {
            return (
              <Button
                type="link"
                onClick={() => {
                  this.handleExport(value);
                }}
              >
                {value}
              </Button>
            );
          } else {
            return null;
          }
        },
      },
      {
        title: '檔案',
        dataIndex: 'files',
        key: 'files',
        width: padding + fontSize * 10,
        align: 'left',
        fixed: 'right',
        render: (value, record) => {
          if (value) {
            return (
              <Container>
                {value.files.map((item, i) => (
                  <Item key={i}>
                    <Link to={`/api/upload/file/${item.file}`} target="_blank">
                      {`${item.name}  `}
                    </Link>
                  </Item>
                ))}
              </Container>
            );
          } else {
            return null;
          }
        },
      },
      {
        ...this.getColumeProps('結算註記', 'settlemented', numberSort, sortField, sortOrder),
        width: padding + fontSize * 6,
        fixed: 'right',
        render: (value, record) => {
          if (value) {
            return '結算完成';
          } else {
            return '等待結算';
          }
        },
      },
      {
        title: '操作',
        width: padding + fontSize * 6,
        dataIndex: 'settlemented',
        key: 'action',
        fixed: 'right',
        render: (value, record) => {
          return (
            <div>
              {record.settlemented ? (
                <Button
                  onClick={() => {
                    this.handleReSettlement(record);
                  }}
                >
                  {'重新結算'}
                </Button>
              ) : (
                <Button
                  onClick={() => {
                    this.handleCancelReSettlement(record);
                  }}
                >
                  {'取消重結'}
                </Button>
              )}
            </div>
          );
        },
      },
    ];

    const totalWidth = columns.reduce((total, elem) => {
      return total + elem.width;
    }, 0);

    return (
      <div>
        <Header
          style={{
            background: '#fff',
            padding: 0,
            textAlign: 'center',
            fontSize: '2em',
          }}
        >
          保發結算資料
        </Header>
        <Content style={{ margin: '24px 16px 0', overflow: 'initial' }}>
          <Card style={{ width: '100%' }}>
            <Row type="flex" justify="end" gutter={[16, 16]}>
              <Col>
                <Button
                  onClick={() => {
                    this.handleSubmit();
                  }}
                >
                  保發結算
                </Button>
              </Col>
            </Row>
            <Table
              dataSource={this.state.dataSource}
              columns={columns}
              defaultPageSize={5}
              pagination={this.state.pagination}
              scroll={{ x: totalWidth }}
              onChange={this.onChange}
            />
          </Card>
        </Content>
      </div>
    );
  }
}

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Item = styled.div`
  position: relative;
  margin-right: 1em;
  width: 4em;
`;
export default PowerCompensation;
