import React, { Component } from 'react';
import axios from 'axios';
import { sprintf } from 'sprintf-js';
import { Form, Input, Tooltip, Icon, Button, Layout, Modal, Card } from 'antd';
import { getToken } from '../utils/setting';
const FormItem = Form.Item;
const { Header, Content } = Layout;

class CreateUserForm extends Component {
  constructor(props) {
    super(props);

    this.handleOk = this.handleOk.bind(this);
    this.handleCancel = this.handleCancel.bind(this);

    this.state = {
      modalTitle: '',
      modalData: '',
      visible: false,
      confirmLoading: false,
      values: null
    };
  }

  showModal = values => {
    const data = (
      <div>
        <div>名稱: {values.name}</div>
      </div>
    );

    this.setState({
      modalTitle: '帳號資料是否正確，確定要創建此資料?',
      modalData: data,
      visible: true,
      values: values
    });
  };

  handleOk = () => {
    this.setState({
      modalTitle: 'Asset帳號創建結果',
      modalData: '正在進行創建，請稍候',
      confirmLoading: true
    });

    axios
      .post(`/api/spv/create`, {
        name: this.state.values.name
      }, { headers: { Authorization: getToken() } })
      .then(res => {
        if (res.data.result === 'ok') {
          this.setState({
            modalData: '創建完成',
            confirmLoading: false,
            values: null
          });

          this.props.form.resetFields();
        } else {
          this.setState({
            modalData: sprintf('創建失敗, 錯誤訊息: (%s)', res.data.error),
            confirmLoading: false,
            values: null
          });
        }
      })
      .catch(error => {
        console.error(error);
      });
  };

  handleCancel = () => {
    this.setState({
      visible: false,
      confirmLoading: false,
      values: null
    });
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.showModal(values);
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;

    const formItemLayout = {
      labelCol: { span: 6 },
      wrapperCol: { span: 14 }
    };

    const tailFormItemLayout = {
      wrapperCol: {
        span: 8,
        offset: 6
      }
    };

    let footer = [];
    if (this.state.values && !this.state.confirmLoading) {
      footer.push(
        <Button key="cancel" onClick={this.handleCancel}>
          取消
        </Button>
      );
      footer.push(
        <Button key="ok" type="primary" loading={this.state.confirmLoading} onClick={this.handleOk}>
          確認
        </Button>
      );
    } else {
      footer.push(
        <Button key="ok" type="primary" loading={this.state.confirmLoading} onClick={this.handleCancel}>
          確認
        </Button>
      );
    }

    return (
      <div>
        <Header
          style={{
            background: '#fff',
            padding: 0,
            textAlign: 'center',
            fontSize: '2em'
          }}
        >
          建置新的Asset帳號
        </Header>
        <Content style={{ margin: '24px 16px 0', overflow: 'initial' }}>
          <Card style={{ width: '100%' }}>
            <Form onSubmit={this.handleSubmit}>
              <FormItem
                {...formItemLayout}
                label={
                  <span>
                    名稱&nbsp;
                    <Tooltip title="用於代表SPV的名稱">
                      <Icon type="question-circle-o" />
                    </Tooltip>
                  </span>
                }
              >
                {getFieldDecorator('name', {
                  rules: [
                    {
                      required: true,
                      message: '請給SPV一個名稱'
                    }
                  ]
                })(<Input placeholder="ex.索拉艾倫" />)}
              </FormItem>
              <FormItem {...tailFormItemLayout}>
                <Button type="primary" htmlType="submit">
                  建立帳號
                </Button>
              </FormItem>
            </Form>
            <Modal
              title={this.state.modalTitle}
              visible={this.state.visible}
              onCancel={this.handleCancel}
              footer={footer}
            >
              {this.state.modalData}
            </Modal>
          </Card>
        </Content>
      </div>
    );
  }
}

const WrappedCreateUserForm = Form.create()(CreateUserForm);

export default WrappedCreateUserForm;
