import React, { useMemo, useState } from "react";
import { BrowserRouter, Switch, Route, Link } from "react-router-dom";
import "./App.css";
import "antd/dist/antd.css";
import axios from "axios";
import { Layout, Menu, Icon, Button, Modal } from "antd";

//
import Col from "./components/Commons/Col";
import Divider from "./components/Commons/Divider";
import JModal from "./components/Commons/Modal";
import Password from "./components/Commons/Input/password";
import Row from "./components/Commons/Row";
import Subtitle from "./components/Commons/Subtitle";

import Home from "./components/Home";
import PvQuery from "./components/PvQuery";
import PvSettle from "./components/PvSettle";
import PowerCompensation from "./components/PowerCompensation";
import PvMaintain from "./components/PvMaintain";
import UserCreate from "./components/UserCreate";
import UserQuery from "./components/UserQuery";
import UserMaintain from "./components/UserMaintain";
import ManagerCreate from "./components/ManagerCreate";
import ManagerQuery from "./components/ManagerQuery";
import ManagerMaintain from "./components/ManagerMaintain";
import ReportShareholderProfit from "./components/Report/ShareholderProfit";
import ReportPowerhouseProfit from "./components/Report/PowerhouseProfit";

import { useProvided } from "./store/index";
import useAuth from "./store/useAuth";

const { Footer, Sider } = Layout;
const SubMenu = Menu.SubMenu;

const InitEditData = {
  oldPwd: { value: "", msg: "" },
  newPwd: { value: "", msg: "" },
  doubleCheckPwd: { value: "", msg: "" },
};
const AppLayout = () => {
  const { user, UnAuthUser } = useProvided(useAuth);
  const [modalOpen, setModalOpen] = useState(false);
  const [editData, setEditData] = useState(InitEditData);

  const handleModalOpen = () => {
    setModalOpen(true);
  };
  const handleModalClose = () => {
    setEditData(InitEditData);
    setModalOpen(false);
  };
  const handleInputChange = (name) => (e) => {
    if (e && e.target) {
      const tmp = { ...editData };
      const tmpValue = e.target.value;
      let errMsg = "";
      switch (name) {
        case "oldPwd":
          if (tmpValue.length >= 4) {
            if (tmp.newPwd.value === tmpValue) {
              tmp.newPwd.value = "";
              tmp.newPwd.msg = "新密碼不得與舊密碼相同";
            }
          } else {
            errMsg = "密碼長度不足";
          }
          break;
        case "newPwd":
          if (tmpValue.length >= 4) {
            if (tmp.oldPwd.value !== tmpValue) {
              if (
                !tmp.doubleCheckPwd.value ||
                tmp.doubleCheckPwd.value === tmpValue
              ) {
                tmp.doubleCheckPwd.msg = "";
              } else {
                tmp.doubleCheckPwd.msg = "輸入的密碼與新密碼並不相符";
              }
            } else {
              errMsg = "請輸入密碼, 且新密碼不得與舊密碼相同";
            }
          } else {
            errMsg = "密碼長度不足";
          }
          break;
        case "doubleCheckPwd":
          if (tmp.newPwd.value !== tmpValue) {
            errMsg = "輸入的密碼與新密碼並不相符";
          }
          break;
        default:
          console.error("should not have this case");
          break;
      }
      tmp[name] = { value: tmpValue, msg: errMsg };
      setEditData(tmp);
    } else {
      //Notify.error('密碼安全性驗證出現錯誤');
      Modal.error({
        title: "訊息提示",
        content: "密碼安全性驗證出現錯誤",
      });
    }
  };
  const handlePwdModify = () => {
    if (
      editData.oldPwd.msg ||
      editData.newPwd.msg ||
      editData.doubleCheckPwd.msg
    ) {
      //Notify.error('輸入的資料仍有誤，請先修正');
      Modal.error({
        title: "訊息提示",
        content: "輸入的資料仍有誤，請先修正",
      });
      return;
    }
    if (
      !editData.oldPwd.value ||
      !editData.newPwd.value ||
      !editData.doubleCheckPwd.value
    ) {
      //Notify.error('請輸入新舊密碼');
      Modal.error({
        title: "訊息提示",
        content: "請輸入新舊密碼",
      });
      return;
    }
    axios
      .post(
        "/api/user/pwd/reset",
        {
          pwd: editData.oldPwd.value,
          newPwd: editData.newPwd.value,
          newPwdChecked: editData.doubleCheckPwd.value,
        },
        { headers: { Authorization: user.token } }
      )
      .then((res) => {
        if (res && res.data && res.data.status) {
          //Notify.success('設定成功，下次登入請使用新密碼');
          Modal.success({
            title: "訊息提示",
            content: "設定成功，下次登入請使用新密碼",
          });
          handleModalClose();
        } else {
          //Notify.error('密碼重設失敗，' + res.data.msg);
          Modal.error({
            title: "錯誤訊息",
            content: "密碼重設失敗，" + res.data.msg,
          });
          setEditData(InitEditData);
        }
      })
      .catch((err) => {
        console.error(err);
        //Notify.error('密碼重設失敗');
        Modal.error({
          title: "錯誤訊息",
          content: "密碼重設失敗",
        });
        setEditData(InitEditData);
      });
  };

  const menus = useMemo(() => {
    const m = {
      home: {
        key: "dashboard",
        icon: "dashboard",
        name: "dashboard",
        linkTo: "/",
      },
      pv: null,
      report: {
        key: "report",
        icon: "file-search",
        name: "SPV報表",
        linkTo: null,
        sub: [
          {
            key: "ShareholderProfit",
            icon: null,
            name: "營運收益",
            linkTo: "/shareholderProfit",
          },
          {
            key: "PowerhouseProfit",
            icon: null,
            name: "電廠收益",
            linkTo: "/powerhouseProfit",
          },
        ],
      },
      spv: null,
      manager: null,
      logout: {
        key: "user",
        icon: "user",
        name: user.account,
        linkTo: null,
        sub: [
          {
            key: "logout",
            icon: null,
            name: "登出",
            linkTo: "/login",
            clickFn: UnAuthUser,
          },
          {
            key: "resetPwd",
            icon: null,
            name: "變更密碼",
            linkTo: null,
            clickFn: handleModalOpen,
          },
        ],
      },
    };

    switch (user.role) {
      case "check":
        m.pv = { key: "pv", icon: "shop", name: "電廠", linkTo: null, sub: [] };
        m.pv.sub.push({
          key: "pvQuery",
          icon: null,
          name: "查詢",
          linkTo: "/pvQuery",
        });
        m.pv.sub.push({
          key: "pvSettle",
          icon: null,
          name: "電費結算",
          linkTo: "/pvSettle",
        });
        m.pv.sub.push({
          key: "powerCompensation",
          icon: null,
          name: "保發結算",
          linkTo: "/powerCompensation",
        });
        break;
      case "secondCheck":
      case "sysAdmin":
        m.pv = { key: "pv", icon: "shop", name: "電廠", linkTo: null, sub: [] };
        m.pv.sub.push({
          key: "pvQuery",
          icon: null,
          name: "查詢",
          linkTo: "/pvQuery",
        });
        m.pv.sub.push({
          key: "pvSettle",
          icon: null,
          name: "電費結算",
          linkTo: "/pvSettle",
        });
        m.pv.sub.push({
          key: "powerCompensation",
          icon: null,
          name: "保發結算",
          linkTo: "/powerCompensation",
        });
        m.spv = {
          key: "spv",
          icon: "home",
          name: "SPV",
          linkTo: null,
          sub: [],
        };
        m.spv.sub.push({
          key: "userCreate",
          icon: null,
          name: "新增",
          linkTo: "/userCreate",
        });
        m.spv.sub.push({
          key: "userQuery",
          icon: null,
          name: "查詢",
          linkTo: "/userQuery",
        });
        m.manager = {
          key: "manager",
          icon: "team",
          name: "管理者",
          linkTo: null,
          sub: [],
        };
        m.manager.sub.push({
          key: "managerCreate",
          icon: null,
          name: "新增",
          linkTo: "/managerCreate",
        });
        m.manager.sub.push({
          key: "managerQuery",
          icon: null,
          name: "查詢",
          linkTo: "/managerQuery",
        });
        break;
      default:
        break;
    }
    return m;
  }, [user, UnAuthUser]);

  const routes = useMemo(() => {
    const r = [];
    switch (user.role) {
      case "check":
        r.push(<Route key={0} path="/pvQuery" component={PvQuery} />);
        r.push(<Route key={1} path="/pvSettle" component={PvSettle} />);
        r.push(
          <Route
            key={2}
            path="/powerCompensation"
            component={PowerCompensation}
          />
        );
        break;
      case "secondCheck":
      case "sysAdmin":
        r.push(<Route key={0} path="/pvQuery" component={PvQuery} />);
        r.push(<Route key={1} path="/pvSettle" component={PvSettle} />);
        r.push(
          <Route
            key={2}
            path="/powerCompensation"
            component={PowerCompensation}
          />
        );
        r.push(
          <Route key={3} path="/pvMaintain/:pvId" component={PvMaintain} />
        );
        r.push(<Route key={4} path="/userCreate" component={UserCreate} />);
        r.push(<Route key={5} path="/userQuery" component={UserQuery} />);
        r.push(
          <Route
            key={6}
            path="/userMaintain/:userId"
            component={UserMaintain}
          />
        );
        r.push(
          <Route key={7} path="/managerCreate" component={ManagerCreate} />
        );
        r.push(<Route key={8} path="/managerQuery" component={ManagerQuery} />);
        r.push(
          <Route
            key={9}
            path="/managerMaintain/:managerId"
            component={ManagerMaintain}
          />
        );
        break;
      default:
        break;
    }
    return r;
  }, [user]);

  return (
    <BrowserRouter>
      <div className="App">
        <Layout>
          <Sider
            style={{
              overflow: "auto",
              height: "100vh",
              position: "fixed",
              left: 0,
            }}
          >
            <div className="logo" />
            <Menu theme="dark" mode="inline" defaultSelectedKeys={["home"]}>
              {menus &&
                Object.keys(menus).map((subMenuKey) => {
                  const item = menus[subMenuKey];

                  if (!item) return null;

                  if (Array.isArray(item.sub) && item.sub.length > 0) {
                    //用 SubMenu
                    const subItem = item.sub.map((sub) => {
                      return (
                        <Menu.Item key={sub.key}>
                          {sub.linkTo ? (
                            <Link to={sub.linkTo} onClick={sub.clickFn}>
                              {sub.icon && <Icon type={sub.icon} />}
                              <span>{sub.name}</span>
                            </Link>
                          ) : (
                            <span onClick={sub.clickFn}>
                              {sub.icon && <Icon type={sub.icon} />}
                              <span>{sub.name}</span>
                            </span>
                          )}
                        </Menu.Item>
                      );
                    });

                    return (
                      <SubMenu
                        key={item.key}
                        title={
                          <span>
                            <Icon type={item.icon} />
                            <span>{item.name}</span>
                          </span>
                        }
                      >
                        {subItem}
                      </SubMenu>
                    );
                  } else {
                    //用 Menu.Item
                    if (item.linkTo) {
                      return (
                        <Menu.Item key={item.key}>
                          <Link to={item.linkTo}>
                            {item.icon && <Icon type={item.icon} />}
                            <span>{item.name}</span>
                          </Link>
                        </Menu.Item>
                      );
                    } else {
                      return (
                        <Menu.Item key={item.key}>
                          {item.icon && <Icon type={item.icon} />}
                          <span>{item.name}</span>
                        </Menu.Item>
                      );
                    }
                  }
                })}
            </Menu>
          </Sider>
          <Layout style={{ marginLeft: 200 }}>
            <Switch>
              <Route exact path="/" component={Home} />
              {/*              <Route path="/pvCreate" component={PvCreate} />*/}
              {/* <Route path="/pvQuery" component={PvQuery} />
              <Route path="/pvSettle" component={PvSettle} />
              <Route path="/powerCompensation" component={PowerCompensation} />
              <Route path="/pvMaintain/:pvId" component={PvMaintain} />
              <Route path="/userCreate" component={UserCreate} />
              <Route path="/userQuery" component={UserQuery} />
              <Route path="/userMaintain/:userId" component={UserMaintain} />
              <Route path="/managerCreate" component={ManagerCreate} />
              <Route path="/managerQuery" component={ManagerQuery} />
              <Route
                path="/managerMaintain/:managerId"
                component={ManagerMaintain}
              /> */}
              <Route
                path="/shareholderProfit"
                component={ReportShareholderProfit}
              />
              <Route
                path="/powerhouseProfit"
                component={ReportPowerhouseProfit}
              />
              {routes}
            </Switch>
            <Footer style={{ textAlign: "center" }}>Powered by SOLA</Footer>
          </Layout>
        </Layout>
        <JModal visible={modalOpen} width="600px">
          <Subtitle dense>變更密碼</Subtitle>
          <Row style={{ padding: "0px 15px" }}>
            <Col span={24}>
              <Password
                label="舊密碼"
                placeholder="請輸入舊的密碼"
                value={editData.oldPwd.value}
                msg={editData.oldPwd.msg}
                onChange={handleInputChange("oldPwd")}
              />
            </Col>
            <Col span={24}>
              <Password
                label="新密碼"
                placeholder="請輸入新密碼"
                value={editData.newPwd.value}
                msg={editData.newPwd.msg}
                onChange={handleInputChange("newPwd")}
              />
            </Col>
            <Col span={24}>
              <Password
                label="確認新密碼"
                placeholder="請再次輸入新密碼"
                value={editData.doubleCheckPwd.value}
                msg={editData.doubleCheckPwd.msg}
                onChange={handleInputChange("doubleCheckPwd")}
              />
            </Col>
          </Row>
          <Divider />
          <Row justify="end" align="middle">
            <Col>
              <Button type="primary" onClick={handlePwdModify}>
                確認修改
              </Button>
            </Col>
            <Col>
              <Button onClick={handleModalClose}>取消</Button>
            </Col>
          </Row>
        </JModal>
      </div>
    </BrowserRouter>
  );
};

export default AppLayout;
