import React from 'react';
import { Layout, Tabs, Card } from 'antd';
import Appointment from './Appointment';
import Permission from './Permission';
const { Header, Content } = Layout;
const TabPane = Tabs.TabPane;

const ManagerMaintain = props => (
  <div>
    <Header
      style={{
        background: '#fff',
        padding: 0,
        textAlign: 'center',
        fontSize: '2em'
      }}
    >
      維護Asset管理者帳號
    </Header>
    <Content
      style={{
        margin: '24px 16px 0',
        overflow: 'initial',
        width: '100%'
      }}
    >
      <Card style={{ width: '100%' }}>
        <Tabs defaultActiveKey="1">
          <TabPane tab="任用設定" key="1">
            <Appointment id={props.match.params.managerId} />
          </TabPane>
          <TabPane tab="權限設定" key="2">
            <Permission id={props.match.params.managerId} />
          </TabPane>
        </Tabs>
      </Card>
    </Content>
  </div>
);

export default ManagerMaintain;
