import React from 'react';
import { Layout, Tabs, Card } from 'antd';
import UserInfo from './UserInfo';
import SpvSetup from './SpvSetup';
const { Header, Content } = Layout;
const TabPane = Tabs.TabPane;

const UserMaintain = props => (
  <div>
    <Header
      style={{
        background: '#fff',
        padding: 0,
        textAlign: 'center',
        fontSize: '2em'
      }}
    >
      維護Asset帳號
    </Header>
    <Content
      style={{
        margin: '24px 16px 0',
        overflow: 'initial',
        width: '100%'
      }}
    >
      <Card style={{ width: '100%' }}>
        <Tabs defaultActiveKey="1">
          <TabPane tab="基本資料" key="1">
            <UserInfo id={props.match.params.userId} />
          </TabPane>
          <TabPane tab="入金作業" key="2">
            Comming Soon!
          </TabPane>
          <TabPane tab="出金作業" key="3">
            Comming Soon!
          </TabPane>
          <TabPane tab="SPV系統" key="4">
            <SpvSetup id={props.match.params.userId} />
          </TabPane>
        </Tabs>
      </Card>
    </Content>
  </div>
);

export default UserMaintain;
