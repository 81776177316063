import React from 'react';
import { Layout } from 'antd';
import PvBillPreparing from './PvBillPreparing';
const { Header, Content } = Layout;
const PvSettle = props => (
  <div>
    <Header
      style={{
        background: '#fff',
        padding: 0,
        textAlign: 'center',
        fontSize: '2em'
      }}
    >
      電費結算資料
    </Header>
    <Content
      style={{
        margin: '24px 16px 0',
        overflow: 'initial',
        width: '100%'
      }}
    >
      <PvBillPreparing />
    </Content>
  </div>
);

export default PvSettle;
