import React, { useState, useCallback, useEffect } from "react";
import axios from "axios";

import Col from "../../Commons/Col";
import Row from "../../Commons/Row";
import Select from "../../Commons/Select";
import DatePicker from "../../Commons/DatePicker";
import { Layout, Card, Button, Table, Divider, message } from "antd";

import { numberFormat } from "../../Utils/Format";
import { getToken } from "../../../utils/setting";
import moment from "moment";
//Export excel
import xlsx from "xlsx";
const { Header, Content } = Layout;

const InitSearchData = {
  userId: "",
  SearchAtSt:
    moment().format("M") > 6
      ? moment().set({
          year: moment().year,
          month: 0,
          date: 1,
        })
      : moment().set({
          year: moment().add(-1, "year").format("YYYY"),
          month: 5,
          date: 1,
        }),
  SearchAtEd:
    moment().format("M") > 6
      ? moment().set({
          year: moment().year,
          month: 5,
          date: 1,
        })
      : moment().set({
          year: moment().add(-1, "year").format("YYYY"),
          month: 11,
          date: 1,
        }),
};

const ShareholderProfit = () => {
  const [displayButton, setdisplayButton] = useState({ display: false });
  const [searchData, setSearchData] = useState(InitSearchData);
  const [UsersInfo, setUsersInfo] = useState([]);
  const [qryDbData, setqryDbData] = useState([]);
  const UserToken = getToken();

  const loadAllUser = useCallback(() => {
    axios
      .get("/api/report/readSpvAll", { headers: { Authorization: UserToken } })
      .then((res) => {
        if (res && res.data && res.data.result) {
          setUsersInfo([
            { label: "全部", value: "" },
            ...res.data.result.rows.map((r) => {
              r.label = r.name;
              r.value = r.id;
              return r;
            }),
          ]);
        } else {
          message.error(res.data.msg);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }, [UserToken]);

  useEffect(() => {
    loadAllUser();
  }, [loadAllUser]);

  const handleSearchReport = useCallback(async () => {
    axios
      .post(
        "/api/report/qryOperatIncome",
        {
          qryUserId: searchData.userId,
          qryAtSt: searchData.SearchAtSt
            ? searchData.SearchAtSt.format("YYYY-MM")
            : searchData.SearchAtSt,
          qryAtEd: searchData.SearchAtEd
            ? searchData.SearchAtEd.format("YYYY-MM")
            : searchData.SearchAtEd,
        },
        { headers: { Authorization: UserToken } }
      )
      .then((res) => {
        if (res && res.data && res.data.result) {
          setqryDbData(res.data.result.rows);
          if (res.data.result.rows.length > 0) {
            setdisplayButton({ display: true });
          } else {
            setdisplayButton({ display: false });
          }
        } else {
          message.error(res.data.msg);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }, [searchData, UserToken]);

  const handleSearchDataChange = (name) => (e) => {
    const tmp = { ...searchData };
    if (e && e.target) {
      tmp[name] = e.target.value;
    } else {
      tmp[name] = e;
    }
    tmp.msg = "";
    setSearchData(tmp);
  };

  //const TEST = ()=>{};
  const handleExport = useCallback(() => {
    if (qryDbData && qryDbData.length > 0) {
      const fields = [
        [
          "SPV名稱",
          "案場名稱",
          "掛表日",
          "計費起日",
          "計費迄日",
          "投資金額",
          "電費收益",
          "保發結算",
        ],
      ];
      qryDbData.forEach((d) => {
        fields.push([
          d.uName,
          d.pName,
          d.validityDateBegin,
          d.rangeBegin,
          d.rangeEnd,
          d.InvestmentAmount,
          d.ElectricityRevenue,
          d.GuaranteedSettlement,
        ]);
      });

      let sheet = xlsx.utils.aoa_to_sheet(fields);
      sheet["!cols"] = [
        { wch: 13 },
        { wch: 35 },
        { wch: 11 },
        { wch: 11 },
        { wch: 11 },
        { wch: 9 },
        { wch: 9 },
        { wch: 9 },
      ];
      // 構造workBook
      let workBook = {
        SheetNames: ["各期營運及收益"],
        Sheets: {
          各期營運及收益: sheet,
        },
      };
      // 將workBook寫入檔案
      xlsx.writeFile(
        workBook,
        searchData.SearchAtSt !== "" && searchData.SearchAtEd !== ""
          ? searchData.SearchAtSt.format("YYYY-MM") +
              "_" +
              searchData.SearchAtEd.format("YYYY-MM") +
              "各期營運及收益報表_" +
              moment().format("YYYYMMDD") +
              ".xlsx"
          : "各期營運及收益報表_" + moment().format("YYYYMMDD") + ".xlsx"
      );
    } else {
      message.error("查無各期營運及收益報表");
    }
  }, [qryDbData, searchData.SearchAtSt, searchData.SearchAtEd]);

  const padding = 32;
  const fontSize = 14;
  const Columns = [
    {
      title: "SPV名稱",
      dataIndex: "uName",
      width: padding + fontSize * 12,
      key: "uName",
    },
    {
      title: "案場名稱",
      dataIndex: "pName",
      width: padding + fontSize * 12,
      key: "pName",
    },
    {
      title: "掛表日",
      dataIndex: "validityDateBegin",
      width: padding + fontSize * 8,
      key: "validityDateBegin",
    },
    {
      title: "計費起日",
      dataIndex: "rangeBegin",
      width: padding + fontSize * 8,
      key: "rangeBegin",
    },
    {
      title: "計費迄日",
      dataIndex: "rangeEnd",
      width: padding + fontSize * 8,
      key: "rangeEnd",
    },
    {
      title: "投資金額",
      dataIndex: "InvestmentAmount",
      width: padding + fontSize * 7,
      key: "InvestmentAmount",
      align: "right",
      render: (v) => numberFormat({ value: v, postfix: "元" }),
    },
    {
      title: "電費收益",
      dataIndex: "ElectricityRevenue",
      width: padding + fontSize * 7,
      key: "ElectricityRevenue",
      align: "right",
      render: (v) => numberFormat({ value: v, postfix: "元" }),
    },
    {
      title: "保發結算",
      dataIndex: "GuaranteedSettlement",
      width: padding + fontSize * 7,
      key: "GuaranteedSettlement",
      align: "right",
      render: (v) => numberFormat({ value: v, postfix: "元" }),
    },
  ];
  const totalWidth = Columns.reduce((total, elem) => {
    return total + elem.width;
  }, 0);

  return (
    <div>
      <Header
        style={{
          background: "#fff",
          padding: 0,
          textAlign: "center",
          fontSize: "2em",
        }}
      >
        各期營運及收益報表
      </Header>
      <Content style={{ margin: "24px 16px 0", overflow: "initial" }}>
        <Card style={{ width: "100%" }}>
          <Row align="bottom">
            <Col span={5}>
              <Select
                label="SPV公司"
                placeholder="請選擇SPV公司"
                options={UsersInfo}
                value={searchData.userId}
                onChange={handleSearchDataChange("userId")}
              />
            </Col>
            <Col span={5}>
              <DatePicker
                label="結算月份_起"
                placeholder="請選擇結算月份_起"
                format="YYYY-MM"
                value={searchData.SearchAtSt}
                onChange={handleSearchDataChange("SearchAtSt")}
              />
            </Col>
            <Col span={5}>
              <DatePicker
                label="結算月份_迄"
                placeholder="請選擇結算月份_迄"
                format="YYYY-MM"
                value={searchData.SearchAtEd}
                onChange={handleSearchDataChange("SearchAtEd")}
              />
            </Col>
            <Col span={2}>
              <Button icon="search" onClick={handleSearchReport}>
                查詢
              </Button>
            </Col>
            {displayButton.display && (
              <Col span={2}>
                <Button icon="export" onClick={handleExport}>
                  匯出
                </Button>
              </Col>
            )}
          </Row>
          <Divider />
          <Table
            dataSource={qryDbData}
            columns={Columns}
            defaultPageSize={5}
            scroll={{ x: totalWidth }}
          />
        </Card>
      </Content>
    </div>
  );
};

export default ShareholderProfit;
