import React, { Component } from 'react';
import axios from 'axios';
import { Select, message } from 'antd';
import { getToken } from '../../utils/setting';
const { Option } = Select;

class Permission extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dataSource: [],
      roleList: []
    };
  }

  componentDidMount() {
    this.loadData();
  }

  loadData = async () => {
    try {
      let res = await axios.get('/api/manager/allRole', { headers: { Authorization: getToken() } });
      const roleList = res.data.roleList;
      res = await axios.get(`/api/manager/permission/${this.props.id}`, { headers: { Authorization: getToken() } });
      this.setState({ dataSource: res.data.permissionList, roleList });
    } catch (e) {
      console.error(e);
    }
  };

  handleChange = (userId, roleId) => {
    axios
      .post(`/api/manager/modifyPermission`, {
        managerId: this.props.id,
        userId: userId,
        roleId: roleId
      }, { headers: { Authorization: getToken() } })
      .then(res => {
        if (res.data.result !== 'ok') {
          message.error('設定失敗');
        }
      })
      .catch(error => {
        console.error(error);
      });
  };

  render() {
    const { dataSource, roleList } = this.state;
    let list = [];
    const options = roleList.map((item, i) => (
      <Option key={i} value={item.id}>
        {item.name} ({item.description})
      </Option>
    ));
    dataSource.forEach((item, index) => {
      list.push(
        <tr key={index}>
          <td style={{ width: '120px' }}>{item.name}</td>
          <td>
            <Select
              defaultValue={item.roleId}
              style={{ width: 180 }}
              onChange={value => this.handleChange(item.userId, value)}
            >
              {options}
            </Select>
          </td>
        </tr>
      );
    });
    return (
      <table>
        <tbody>{list}</tbody>
      </table>
    );
  }
}

export default Permission;
