import React, { useState } from 'react';
import { Icon, Input, Button, message } from 'antd';
import axios from 'axios';
import styled from 'styled-components';
import { useProvided } from '../../store/index';
import useAuth from '../../store/useAuth';

const IconStyle = { color: 'rgba(0, 0, 0, 0.25)' };

const Login = () => {
  const { AuthUser } = useProvided(useAuth);
  const [account, setAccount] = useState('');
  const [pwd, setPwd] = useState('');
  const [errMsg, setErrMsg] = useState('');

  const handleAccountChange = (e) => {
    setAccount(e.target.value);
    setErrMsg('');
  };
  const handlePwdChange = (e) => {
    setPwd(e.target.value);
    setErrMsg('');
  };

  const handleLogin = () => {
    if (!account || !pwd) {
      setErrMsg('請輸入帳號與密碼');
      return;
    }

    axios
      .post('/api/auth/login', { account, pwd })
      .then((res) => {
        if (res && res.data && res.data.status) {
          const data = res.data.result || {};
          AuthUser(data);
        } else {
          message.error(res.data.msg, 1);
        }
      })
      .catch((err) => {
        console.log(err);
        message.error(`登入失敗：${err}`, 1);
      });
  };

  return (
    <Div>
      <InnerDiv>
        <Title>
          <div>SolaAdmin</div>
          <div>案場資料管理系統</div>
        </Title>
        <Row>
          <Input
            prefix={<Icon type="user" style={IconStyle} />}
            placeholder="Username"
            onChange={handleAccountChange}
          />
        </Row>
        <Row>
          <Input
            type="password"
            prefix={<Icon type="lock" style={IconStyle} />}
            placeholder="Password"
            onChange={handlePwdChange}
            onPressEnter={handleLogin}
          />
        </Row>
        <Row>
          <ErrMsg>{errMsg}</ErrMsg>
        </Row>
        <Row>
          <Button type="primary" size="large" onClick={handleLogin}>
            登入
          </Button>
        </Row>
      </InnerDiv>
    </Div>
  );
};

const Div = styled.div`
  width: 99vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  padding-top: 100px;
`;
const InnerDiv = styled.div`
  width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const Title = styled.div`
  width: 100%;
  font-size: 30px;
  margin-bottom: 1em;
  text-align: center;
`;
const Row = styled.div`
  width: 80%;
  margin: 10px 0px;
  text-align: center;
`;
const ErrMsg = styled.div`
  font-size: 16px;
  color: #f00;
`;

export default Login;
