import React from 'react';
import { render } from 'react-dom';
import './index.css';
import { Provider } from './store/index';
import useAuth from './store/useAuth';
import App from './App';

render(
	<Provider inject={[useAuth]}>
		<App />
	</Provider>,
	document.getElementById('root')
);
