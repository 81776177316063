import React, { useMemo } from 'react';
import styled from 'styled-components';
import { Select } from 'antd';
const { Option } = Select;

const JSelect = ({ label, options, msg, canSearch = true, required = false, ...props }) => {
  const optionDOM = useMemo(() => {
    if (options && Array.isArray(options) && options.length > 0) {
      return options.map((item, idx) => {
        if (item) {
          return (
            <Option key={idx} value={item.value} disabled={item.disabled === true ? true : false}>
              {item.label}
            </Option>
          );
        } else {
          return null;
        }
      });
    } else {
      return null;
    }
  }, [options]);
  const searchProps = useMemo(() => {
    if (canSearch === true) {
      return {
        showSearch: true,
        optionFilterProp: 'children',
        filterOption: (input, option) => {
          return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
        },
      };
    } else {
      return {};
    }
  }, [canSearch]);

  return (
    <Div>
      {label && <LabelDiv required={required}>{label}</LabelDiv>}
      <InputDiv>
        <Select style={{ width: '100%' }} {...searchProps} {...props}>
          {optionDOM}
        </Select>
      </InputDiv>
      <MsgDiv>{msg}</MsgDiv>
    </Div>
  );
};

const Div = styled.div`
  width: 100%;
`;
const LabelDiv = styled.div`
  width: 100%;
  font-size: 16px;
  margin-bottom: 5px;

  ${(p) =>
    p.required
      ? `
        font-weight: bold;
        ::after {
            content: " *";
            color: #f00;
        }
        `
      : `
        ::after {
            content: "*";
            color: #ffffff;
        }
        `}
`;
const InputDiv = styled.div`
  width: 100%;
`;
const MsgDiv = styled.div`
  width: 100%;
  font-size: 12px;
  color: #f00;
`;

export default JSelect;
