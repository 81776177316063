import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
import { Table, Button } from 'antd';
import key from '../../config/key';
import { numberFormat } from '../Utils/Format';
import { getToken } from '../../utils/setting';

class PvBill extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dataSource: [],
    };
  }

  componentDidMount() {
    this.loadData();
  }

  loadData() {
    return axios
      .get(`/api/bill/all/${this.props.id}`, { headers: { Authorization: getToken() } })
      .then((res) => {
        if (res.data.status) {
          this.setState({ dataSource: res.data.result.bills });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  handleReport = (id) => {
    //TODO: show loading report
    axios
      .get(key.reportURL + '/api/v1/report/getPdf', {
        //headers: { 'Authorization': this.props.auth.token },
        responseType: 'blob',
        params: {
          page: 'BillReport/' + id,
        },
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('target', '_blank');
        document.body.appendChild(link);
        link.click();
      });
  };

  render() {
    const padding = 32;
    const fontSize = 14;
    const columns = [
      {
        title: '檔案',
        dataIndex: 'file',
        key: 'file',
        width: padding + fontSize * 6,
        align: 'center',
        fixed: 'left',
        render: (text, record) => {
          if (record.file) {
            return (
              <Link to={`/api/upload/file/${record.file}`} target="_blank">
                查看
              </Link>
            );
          } else {
            return null;
          }
        },
      },
      {
        title: '計費起始日',
        dataIndex: 'rangeBegin',
        width: padding + fontSize * 6,
        key: 'rangeBillBegin',
        fixed: 'left',
      },
      {
        title: '計費結束日',
        dataIndex: 'rangeEnd',
        width: padding + fontSize * 6,
        key: 'rangeBillEnd',
        fixed: 'left',
        render: (v) => moment(v).subtract(1, 'days').format('YYYY-MM-DD'),
      },
      {
        title: '抄表起始日',
        dataIndex: 'rangeBegin',
        width: padding + fontSize * 6,
        key: 'rangeBegin',
      },
      {
        title: '抄表結束日',
        dataIndex: 'rangeEnd',
        width: padding + fontSize * 6,
        key: 'rangeEnd',
      },
      {
        title: '發電度數',
        dataIndex: 'totalPower',
        width: padding + fontSize * 5,
        key: 'totalPower',
        align: 'right',
        render: (v) => numberFormat({ value: v, postfix: '度' }),
      },
      {
        title: '發電金額',
        dataIndex: 'powerAmount',
        width: padding + fontSize * 5,
        key: 'powerAmount',
        align: 'right',
        render: (v) => numberFormat({ value: v, postfix: '元' }),
      },
      {
        title: '補付(扣)電費',
        dataIndex: 'deductPowerAmount',
        width: padding + fontSize * 6,
        key: 'deductAmount',
        align: 'right',
        render: (v) => numberFormat({ value: v, postfix: '元' }),
      },
      {
        title: '營業稅',
        dataIndex: 'salesTax',
        width: padding + fontSize * 6,
        key: 'salesTax',
        align: 'right',
        render: (v) => numberFormat({ value: v, postfix: '元' }),
      },
      {
        title: '總電費(含稅)',
        dataIndex: 'incomeWithTax',
        width: padding + fontSize * 6,
        key: 'incomeWithTax',
        align: 'right',
        render: (v) => numberFormat({ value: v, postfix: '元' }),
      },
      {
        title: '本期電表租費',
        dataIndex: 'powerRent',
        width: padding + fontSize * 6,
        key: 'powerRent',
        align: 'right',
        render: (v) => numberFormat({ value: v, postfix: '元' }),
      },
      {
        title: '電表租費收(退)',
        dataIndex: 'makeupPowerRent',
        width: padding + fontSize * 7,
        key: 'makeupRent',
        align: 'right',
        render: (v) => numberFormat({ value: v, postfix: '元' }),
      },
      {
        title: '電表租費',
        dataIndex: 'meterRentCost',
        width: padding + fontSize * 5,
        key: 'meterRentCost',
        align: 'right',
        render: (v) => numberFormat({ value: v, postfix: '元' }),
      },
      {
        title: '收益權分潤',
        dataIndex: 'profit',
        width: padding + fontSize * 6,
        key: 'profit',
        align: 'right',
        render: (v) => numberFormat({ value: v, postfix: '元' }),
      },
      {
        title: '租金(固定)',
        dataIndex: 'rentCostFixed',
        width: padding + fontSize * 5,
        key: 'rentCostFixed',
        align: 'right',
        render: (v) => numberFormat({ value: v, postfix: '元' }),
      },
      {
        title: '租金(浮動)',
        dataIndex: 'rentCostFloat',
        width: padding + fontSize * 5,
        key: 'rentCostFloat',
        align: 'right',
        render: (v) => numberFormat({ value: v, postfix: '元' }),
      },
      {
        title: '營運費(固定)',
        dataIndex: 'operateCostFixed',
        width: padding + fontSize * 6,
        key: 'operateCostFixed',
        align: 'right',
        render: (v) => numberFormat({ value: v, postfix: '元' }),
      },
      {
        title: '營運費(浮動)',
        dataIndex: 'operateCostFloat',
        width: padding + fontSize * 6,
        key: 'operateCostFloat',
        align: 'right',
        render: (v) => numberFormat({ value: v, postfix: '元' }),
      },
      {
        title: '服務費',
        dataIndex: 'serviceCostFloat',
        width: padding + fontSize * 4,
        key: 'serviceCostFloat',
        align: 'right',
        render: (v) => numberFormat({ value: v, postfix: '元' }),
      },
      {
        title: '保險費',
        dataIndex: 'insuranceCost',
        width: padding + fontSize * 4,
        key: 'insuranceCost',
        align: 'right',
        render: (v) => numberFormat({ value: v, postfix: '元' }),
      },
      {
        title: '貸款費',
        dataIndex: 'loanCost',
        width: padding + fontSize * 4,
        key: 'loanCost',
        align: 'right',
        render: (v) => numberFormat({ value: v, postfix: '元' }),
      },
      {
        title: '貸款手續費',
        dataIndex: 'loanFeeCost',
        width: padding + fontSize * 5,
        key: 'loanFeeCost',
        align: 'right',
        render: (v) => numberFormat({ value: v, postfix: '元' }),
      },
      {
        title: '分潤方式',
        dataIndex: 'inTrust',
        width: padding + fontSize * 5,
        key: 'inTrust',
        render: (v) => (v === true ? '信託管理' : '直接撥款'),
      },
      {
        title: '狀態',
        key: 'cleared',
        width: padding + fontSize * 4,
        fixed: 'right',
        render: (text, record) => {
          if (record.cleared === null) {
            return '待分潤';
          } else if (record.cleared === false) {
            return '分潤中';
          } else {
            return '已分潤';
          }
        },
      },
      {
        title: '操作',
        width: padding + fontSize * 5,
        key: 'action',
        fixed: 'right',
        render: (text, record) => {
          //TODO: 信託報表
          if (record.cleared) {
            return (
              <Button
                key={2}
                onClick={() => {
                  this.handleReport(record.id);
                }}
              >
                {'報表'}
              </Button>
            );
          } else {
            return null;
          }
        },
      },
    ];
    const totalWidth = columns.reduce((total, elem) => {
      return total + elem.width;
    }, 0);
    return (
      <div>
        <Table
          dataSource={this.state.dataSource}
          columns={columns}
          defaultPageSize={5}
          scroll={{ x: totalWidth }}
        />
      </div>
    );
  }
}

export default PvBill;
