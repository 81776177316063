import React, { Component } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Table, Modal } from 'antd';
import { getToken } from '../../utils/setting';

import { numberFormat, percentFormat } from '../Utils/Format';

class PvLoan extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dataSource: []
    };
  }

  componentDidMount() {
    this.loadData();
  }

  loadData() {
    return axios
      .get(`/api/loan/${this.props.id}`, { headers: { Authorization: getToken() } })
      .then(res => {
        if (res.data.status) {
          this.setState({ dataSource: res.data.result.loans });
        }
      })
      .catch(error => {
        Modal.error({
          title: '錯誤訊息',
          content: error.response.data.msg || '很抱歉, 系統發生錯誤, 請稍後重試或聯絡系統管理員'
        });
      });
  }

  render() {
    const padding = 32;
    const fontSize = 14;
    const columns = [
      {
        title: '貸款起始日',
        dataIndex: 'beginDate',
        key: 'beginDate',
        width: padding + fontSize * 6,
        align: 'center'
      },
      {
        title: '貸款年限',
        dataIndex: 'period',
        key: 'period',
        width: padding + fontSize * 6,
        align: 'center',
        render: v => numberFormat({ value: v, postfix: '年' })
      },
      {
        title: '貸款金額',
        dataIndex: 'amount',
        key: 'amount',
        width: padding + fontSize * 6,
        align: 'right',
        render: v => numberFormat({ value: v, postfix: '元' })
      },
      {
        title: '貸款利率',
        dataIndex: 'rate',
        key: 'rate',
        width: padding + fontSize * 6,
        align: 'right',
        render: v => percentFormat(v)
      },
      {
        title: '檔案',
        dataIndex: 'fileName',
        key: 'fileName',
        width: padding + fontSize * 6,
        align: 'left',
        render: (text, record) => {
          if (record.files) {
            return (
              <Container>
                {record.files.map((file, i) => (
                  <Item key={i}>
                    <Link to={`/api/upload/file/${file}`} target="_blank">
                      {`查看${i + 1}  `}
                    </Link>
                  </Item>
                ))}
              </Container>
            );
          } else {
            return null;
          }
        }
      }
    ];
    return (
      <div>
        <Table dataSource={this.state.dataSource} columns={columns} />
      </div>
    );
  }
}

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Item = styled.div`
  position: relative;
  width: 4em;
`;

export default PvLoan;
