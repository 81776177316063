import React, { Component } from 'react';
import moment from 'moment';
import { Form, Icon, DatePicker, InputNumber, Button, Modal, Tooltip, Row, Col } from 'antd';
const FormItem = Form.Item;
const RangePicker = DatePicker.RangePicker;

class BillPreparingModifyDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: true,
    };
  }

  handlePowerChange = (totalPower) => {
    const values = this.props.form.getFieldsValue(['sellPrice', 'deductAmount']);

    this.props.form.setFieldsValue({
      powerAmount: Math.round(totalPower * values.sellPrice),
      incomeWithTaxEstimated:
        Math.round(totalPower * values.sellPrice) +
        (values.deductAmount !== undefined ? values.deductAmount : 0) +
        Math.round(
          (Math.round(totalPower * values.sellPrice) +
            (values.deductAmount !== undefined ? values.deductAmount : 0)) *
            0.05
        ),
    });
  };

  handleDeductAmountChange = (amount) => {
    const values = this.props.form.getFieldsValue(['totalPower', 'sellPrice']);

    this.props.form.setFieldsValue({
      incomeWithTaxEstimated:
        Math.round(values.totalPower * values.sellPrice) +
        amount +
        Math.round((Math.round(values.totalPower * values.sellPrice) + amount) * 0.05),
    });
  };

  handlePowerRentChange = (power) => {
    const values = this.props.form.getFieldsValue(['makeupRent']);

    this.props.form.setFieldsValue({
      meterRentCost: power + (values.makeupRent !== undefined ? values.makeupRent : 0),
    });
  };

  handleMakeupRentChange = (makeup) => {
    const values = this.props.form.getFieldsValue(['powerRent']);

    this.props.form.setFieldsValue({
      meterRentCost: (values.powerRent !== undefined ? values.powerRent : 0) + makeup,
    });
  };

  handleCancel = () => {
    this.setState({
      visible: false,
    });
    setTimeout(() => this.props.onHide(), 500);
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.onModify(values);
      }
    });
  };

  checkIncomeWithTax = (rule, value, callback) => {
    if (value) {
      const values = this.props.form.getFieldsValue(['incomeWithTaxEstimated']);

      if (Math.abs(values.incomeWithTaxEstimated - value) > 1) {
        callback('目前金額與預估金額差異太大, 請再次確認');
        return;
      }
    }
    callback();
  };

  render() {
    const { getFieldDecorator } = this.props.form;

    const formItemLayout = {
      labelCol: { span: 9 },
      wrapperCol: { span: 14 },
    };

    const tailFormItemLayout = {
      wrapperCol: {
        span: 8,
        offset: 20,
      },
    };

    const incomeWithTaxEstimated =
      Math.round(this.props.data.totalPower * this.props.sellPrice) +
      (this.props.data.deductPowerAmount === undefined || this.props.data.deductPowerAmount === null
        ? 0
        : this.props.data.deductPowerAmount) +
      Math.round(
        (Math.round(this.props.data.totalPower * this.props.sellPrice) +
          (this.props.data.deductPowerAmount === undefined || this.props.data.deductPowerAmount === null
            ? 0
            : this.props.data.deductPowerAmount)) *
          0.05
      );

    return (
      <Modal
        title="修改電費單"
        visible={this.state.visible}
        onCancel={this.handleCancel}
        footer={null}
        width="750px"
      >
        <Form onSubmit={this.handleSubmit}>
          <Row>
            <Col span={12}>
              <FormItem
                {...formItemLayout}
                label={
                  <span>
                    抄表期間&nbsp;
                    <Tooltip title="請參考電費單上本期抄表紀錄，填入本次抄表日&下次抄表日">
                      <Icon type="question-circle-o" />
                    </Tooltip>
                  </span>
                }
              >
                {getFieldDecorator('range', {
                  initialValue: [moment(this.props.data.rangeBegin), moment(this.props.data.rangeEnd)],
                  rules: [
                    {
                      type: 'array',
                      required: true,
                      message: '請輸入有效期間',
                    },
                  ],
                })(<RangePicker placeholder={['本次抄表日', '下次抄表日']} />)}
              </FormItem>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <FormItem
                {...formItemLayout}
                label={
                  <span>
                    發電度數&nbsp;
                    <Tooltip title="請參考電費單上的計費度數">
                      <Icon type="question-circle-o" />
                    </Tooltip>
                  </span>
                }
              >
                {getFieldDecorator('totalPower', {
                  initialValue: this.props.data.totalPower,
                  rules: [
                    {
                      type: 'number',
                      required: true,
                      message: '請輸入發電度數',
                    },
                  ],
                })(<InputNumber style={{ width: '170px' }} min={1} onChange={this.handlePowerChange} />)}
                <span className="ant-form-text"> 度</span>
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem {...formItemLayout} label={<span>躉售電價</span>}>
                {getFieldDecorator('sellPrice', {
                  initialValue: this.props.sellPrice,
                  rules: [
                    {
                      type: 'number',
                      required: true,
                      message: '電廠資料尚未建立 "躉售電價" 資料',
                    },
                  ],
                })(<InputNumber style={{ width: '170px' }} min={1} disabled />)}
                <span className="ant-form-text"> 元</span>
              </FormItem>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <FormItem {...formItemLayout} label={<span>發電金額</span>}>
                {getFieldDecorator('powerAmount', {
                  initialValue:
                    this.props.data.powerAmount === undefined || this.props.data.powerAmount === null
                      ? Math.round(this.props.data.totalPower * this.props.sellPrice)
                      : this.props.data.powerAmount,
                  rules: [
                    {
                      type: 'number',
                      required: true,
                      message: '請輸入發電金額',
                    },
                  ],
                })(<InputNumber style={{ width: '170px' }} min={0} />)}
                <span className="ant-form-text"> 元</span>
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem {...formItemLayout} label={<span>補付(扣)電費</span>}>
                {getFieldDecorator('deductAmount', {
                  initialValue:
                    this.props.data.deductPowerAmount === undefined ||
                    this.props.data.deductPowerAmount === null
                      ? 0
                      : this.props.data.deductPowerAmount,
                  rules: [
                    {
                      type: 'number',
                      message: '請輸入補付(扣)電費',
                    },
                  ],
                })(<InputNumber style={{ width: '170px' }} onChange={this.handleDeductAmountChange} />)}
                <span className="ant-form-text"> 元</span>
              </FormItem>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <FormItem {...formItemLayout} label={<span>預估電費收入</span>}>
                {getFieldDecorator('incomeWithTaxEstimated', {
                  initialValue: incomeWithTaxEstimated,
                })(<InputNumber style={{ width: '170px' }} min={1} disabled />)}
                <span className="ant-form-text"> 元</span>
              </FormItem>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <FormItem
                {...formItemLayout}
                label={
                  <span>
                    電費收入&nbsp;
                    <Tooltip title="請參考電費單上的含稅總電費">
                      <Icon type="question-circle-o" />
                    </Tooltip>
                  </span>
                }
              >
                {getFieldDecorator('incomeWithTax', {
                  initialValue: this.props.data.incomeWithTax,
                  rules: [
                    {
                      type: 'number',
                      required: true,
                      message: '請輸入含稅總電費',
                    },
                    {
                      validator: this.checkIncomeWithTax,
                    },
                  ],
                })(<InputNumber style={{ width: '170px' }} min={1} />)}
                <span className="ant-form-text"> 元</span>
              </FormItem>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <FormItem {...formItemLayout} label={<span>本期電表租費</span>}>
                {getFieldDecorator('powerRent', {
                  initialValue: this.props.data.powerRent,
                  rules: [
                    {
                      type: 'number',
                      required: true,
                      message: '請輸入本期電表租費',
                    },
                  ],
                })(<InputNumber style={{ width: '170px' }} min={0} onChange={this.handlePowerRentChange} />)}
                <span className="ant-form-text"> 元</span>
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem {...formItemLayout} label={<span>電表租費補收(退)</span>}>
                {getFieldDecorator('makeupRent', {
                  initialValue:
                    this.props.data.makeupPowerRent === undefined || this.props.data.makeupPowerRent === null
                      ? 0
                      : this.props.data.makeupPowerRent,
                  rules: [
                    {
                      type: 'number',
                      message: '請輸入電表租費補收(退)',
                    },
                  ],
                })(<InputNumber style={{ width: '170px' }} onChange={this.handleMakeupRentChange} />)}
                <span className="ant-form-text"> 元</span>
              </FormItem>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <FormItem
                {...formItemLayout}
                label={
                  <span>
                    電表租費&nbsp;
                    <Tooltip title="請參考電費單上的電表租費">
                      <Icon type="question-circle-o" />
                    </Tooltip>
                  </span>
                }
              >
                {getFieldDecorator('meterRentCost', {
                  initialValue: this.props.data.meterRentCost,
                  rules: [
                    {
                      type: 'number',
                      required: true,
                      message: '請輸入電表租費',
                    },
                  ],
                })(<InputNumber style={{ width: '170px' }} min={0} />)}
                <span className="ant-form-text"> 元</span>
              </FormItem>
            </Col>
          </Row>
          <FormItem {...tailFormItemLayout}>
            <Button type="primary" htmlType="submit">
              確定
            </Button>
          </FormItem>
        </Form>
      </Modal>
    );
  }
}

const WrappedBillPreparingModifyDialogForm = Form.create()(BillPreparingModifyDialog);

export default WrappedBillPreparingModifyDialogForm;
