import React, { Component } from 'react';
import axios from 'axios';
import { Transfer, message } from 'antd';
import { getToken } from '../../utils/setting';

class Appointment extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dataSource: [],
      targetKeys: [],
      selectedKeys: []
    };
  }

  componentDidMount() {
    this.loadData();
  }

  loadData = async () => {
    try {
      let res = await axios.get(`/api/manager/info/${this.props.id}`, { headers: { Authorization: getToken() } });
      const email = res.data.info[0].email;

      res = await axios.get(`/api/manager/appointment/${this.props.id}`, { headers: { Authorization: getToken() } });
      const keyList = res.data.spvList.map(item => item.key);

      res = await axios.get(`/api/manager/allSpv`, { headers: { Authorization: getToken() } });
      this.setState({ dataSource: res.data.spvList, targetKeys: keyList, email });
    } catch (e) {
      console.error(e);
    }
  };

  handleChange = (nextTargetKeys, direction, moveKeys) => {
    axios
      .post(`/api/manager/assign`, {
        managerId: this.props.id,
        spvList: nextTargetKeys
      }, { headers: { Authorization: getToken() } })
      .then(res => {
        if (res.data.result === 'ok') {
          this.setState({ targetKeys: nextTargetKeys });
        } else {
          message.error('設定失敗');
        }
      })
      .catch(error => {
        console.error(error);
      });
  };

  handleSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
    this.setState({ selectedKeys: [...sourceSelectedKeys, ...targetSelectedKeys] });
  };

  render() {
    const { dataSource, targetKeys, selectedKeys, email } = this.state;
    return (
      <div>
        <div>帳號: {email}</div>
        <Transfer
          dataSource={dataSource}
          titles={['未指派', '已指派']}
          listStyle={{
            width: '40%',
            height: '400px'
          }}
          targetKeys={targetKeys}
          selectedKeys={selectedKeys}
          onChange={this.handleChange}
          onSelectChange={this.handleSelectChange}
          render={item => item.title}
        />
      </div>
    );
  }
}

export default Appointment;
