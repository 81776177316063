import React, { Component } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { Layout, Table, Card, Button } from 'antd';
import { getToken } from '../utils/setting';
const { Header, Content } = Layout;

class ManagerQuery extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dataSource: []
    };
  }

  componentDidMount() {
    this.loadData();
  }

  loadData() {
    return axios
      .get(`/api/manager/all`, { headers: { Authorization: getToken() } })
      .then(res => {
        this.setState({ dataSource: res.data.managers });
      })
      .catch(error => {
        console.error(error);
      });
  }

  handleSendPassword = email => {
    axios
      .post(`/api/manager/sendPasswordMail`, {
        email
      }, { headers: { Authorization: getToken() } })
      .then(res => {
        if (res.data.result === 'ok') {
          this.loadData();
        }
      })
      .catch(error => {
        console.error(error);
      });
  };

  render() {
    const padding = 32;
    const fontSize = 14;
    const columns = [
      {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
        width: padding + fontSize * 15
      },
      {
        title: '密碼',
        dataIndex: 'pwd',
        key: 'pwd',
        width: padding + fontSize * 8,
        render: (text, record) => {
          if (text === null) {
            return (
              <Button
                onClick={() => {
                  this.handleSendPassword(record.email);
                }}
              >
                發送
              </Button>
            );
          } else {
            return '已發送';
          }
        }
      },
      {
        title: '操作',
        key: 'action',
        width: padding + fontSize * 2,
        render: (text, record) => (
          <span>
            <Link to={`/ManagerMaintain/${record.id}`}>維護</Link>
          </span>
        )
      }
    ];

    const totalWidth = columns.reduce((total, elem) => {
      return total + elem.width;
    }, 0);

    return (
      <div>
        <Header
          style={{
            background: '#fff',
            padding: 0,
            textAlign: 'center',
            fontSize: '2em'
          }}
        >
          查詢Asset管理者帳號
        </Header>
        <Content style={{ margin: '24px 16px 0', overflow: 'initial' }}>
          <Card style={{ width: '100%' }}>
            <Table
              dataSource={this.state.dataSource}
              columns={columns}
              defaultPageSize={5}
              scroll={{ x: totalWidth }}
            />
          </Card>
        </Content>
      </div>
    );
  }
}

export default ManagerQuery;
