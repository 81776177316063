import React, { Component } from 'react';
import axios from 'axios';

import valueToName from './Definition/ValueToName';
import stageList from './Definition/StageList';
import statusList from './Definition/StatusList';
import withTaxList from './Definition/WithTaxList';
import decayList from './Definition/DecayList';
import { numberFormat, percentFormat } from './Utils/Format';
import { numberSort, stringSort } from './Utils/Sort';
import { getPagination, setPagination } from './Utils/Cache';

import { Link } from 'react-router-dom';
import { Layout, Table, Card, Modal, Input, Button, Icon } from 'antd';
import { getToken } from '../utils/setting';
const { Header, Content } = Layout;

class PvQuery extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dataSource: [],
      pagination: getPagination()
    };
  }

  componentDidMount() {
    this.loadData();
  }

  loadData() {
    return axios
      .get(`/api/pv/list`, { headers: { Authorization: getToken() } })
      .then(res => {
        if (res.data.status) {
          this.setState({ dataSource: res.data.result.pvList });
        }
      })
      .catch(error => {
        Modal.error({
          title: '錯誤訊息',
          content: error.response.data.msg || '很抱歉, 系統發生錯誤, 請稍後重試或聯絡系統管理員'
        });
      });
  }

  getColumeProps = (columnName, dataIndex, sortMethod, sortField, sortOrder, searchable, filters) => {
    let props = {
      title: columnName,
      dataIndex: dataIndex,
      key: dataIndex,
      sorter: (a, b) => sortMethod(a[dataIndex], b[dataIndex]),
      sortOrder: sortField === dataIndex ? sortOrder : false
    };

    if (searchable) {
      props = Object.assign(props, this.getColumnSearchProps(columnName, dataIndex, filters || {}));
    }
    return props;
  };

  getColumnSearchProps = (columnName, dataIndex, filters) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`搜尋 ${columnName}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          搜尋
        </Button>
        <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          重置
        </Button>
      </div>
    ),
    filterIcon: filtered => <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : false,
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    filteredValue: filters[dataIndex]
  });

  onChange = (pagination, filters, sorter) => {
    const paginationInfo = {
      current: pagination.current,
      sortOrder: sorter.order,
      sortField: sorter.field,
      filters: filters
    };
    setPagination(paginationInfo);
    this.setState({ pagination: paginationInfo });
  };

  handleSearch = (selectedKeys, confirm) => {
    confirm();
  };

  handleReset = clearFilters => {
    clearFilters();
  };

  render() {
    const { sortField, sortOrder, filters } = this.state.pagination;

    const padding = 32;
    const fontSize = 14;
    const columns = [
      {
        ...this.getColumeProps('索拉代碼', 'id', stringSort, sortField, sortOrder, true, filters),
        width: padding + fontSize * 7,
        fixed: 'left'
      },
      {
        ...this.getColumeProps('索拉名稱', 'solaName', stringSort, sortField, sortOrder, true, filters),
        width: padding + fontSize * 8,
        fixed: 'left'
      },
      {
        ...this.getColumeProps('電廠名稱', 'name', stringSort, sortField, sortOrder, true, filters),
        width: padding + fontSize * 12,
        fixed: 'left'
      },
      {
        ...this.getColumeProps('專案代碼', 'projectCode', stringSort, sortField, sortOrder, true, filters),
        width: padding + fontSize * 7
      },
      {
        ...this.getColumeProps('電廠擁有者', 'owner', stringSort, sortField, sortOrder),
        width: padding + fontSize * 13
      },
      {
        ...this.getColumeProps('裝置容量', 'capacity', numberSort, sortField, sortOrder),
        width: padding + fontSize * 8,
        align: 'right',
        render: v => numberFormat({ value: v, postfix: 'kWp' })
      },
      {
        ...this.getColumeProps('發電衰減率', 'decay', numberSort, sortField, sortOrder),
        width: padding + fontSize * 8,
        align: 'right',
        render: v => valueToName(decayList, v)
      },
      {
        ...this.getColumeProps('保證發電量', 'annualAmount', numberSort, sortField, sortOrder),
        width: padding + fontSize * 8,
        align: 'right',
        render: v => numberFormat({ value: v, postfix: 'kWp' })
      },
      {
        ...this.getColumeProps('建置階段', 'stage', numberSort, sortField, sortOrder),
        width: padding + fontSize * 12,
        render: v => valueToName(stageList, v)
      },
      {
        ...this.getColumeProps('狀態', 'status', numberSort, sortField, sortOrder),
        width: padding + fontSize * 5,
        render: v => valueToName(statusList, v)
      },
      {
        ...this.getColumeProps('單位成本', 'constructionCost', numberSort, sortField, sortOrder),
        width: padding + fontSize * 10,
        align: 'right',
        render: v => numberFormat({ value: v, postfix: '元' })
      },
      {
        ...this.getColumeProps('建置總成本', 'totalCapital', numberSort, sortField, sortOrder),
        width: padding + fontSize * 10,
        align: 'right',
        render: v => numberFormat({ value: v, postfix: '元' })
      },
      {
        ...this.getColumeProps('投入成本', 'ownedCapital', numberSort, sortField, sortOrder),
        width: padding + fontSize * 10,
        align: 'right',
        render: v => numberFormat({ value: v, postfix: '元' })
      },
      {
        ...this.getColumeProps('發電起始日', 'validityDateBegin', stringSort, sortField, sortOrder),
        width: padding + fontSize * 8
      },
      {
        ...this.getColumeProps('售電年限', 'validityDuration', numberSort, sortField, sortOrder),
        width: padding + fontSize * 6,
        render: v => numberFormat({ value: v, postfix: '年' })
      },
      {
        ...this.getColumeProps('售電價格', 'sellPrice', numberSort, sortField, sortOrder),
        width: padding + fontSize * 6,
        align: 'right',
        render: v => numberFormat({ value: v, postfix: '元' })
      },
      {
        ...this.getColumeProps('營運固定費率', 'operationRateByCapital', numberSort, sortField, sortOrder),
        width: padding + fontSize * 8,
        align: 'right',
        render: v => percentFormat(v)
      },
      {
        ...this.getColumeProps('營運浮動費率', 'operationRateByIncome', numberSort, sortField, sortOrder),
        width: padding + fontSize * 8,
        align: 'right',
        render: v => percentFormat(v)
      },
      {
        ...this.getColumeProps('索拉服務費率', 'serviceRateByIncome', numberSort, sortField, sortOrder),
        width: padding + fontSize * 8,
        align: 'right',
        render: v => percentFormat(v)
      },
      {
        ...this.getColumeProps('租金固定費用(年)', 'rentYearCost', numberSort, sortField, sortOrder),
        width: padding + fontSize * 8,
        align: 'right',
        render: v => numberFormat({ value: v, postfix: '元' })
      },
      {
        ...this.getColumeProps('租金浮動費率', 'rentRateByIncome', numberSort, sortField, sortOrder),
        width: padding + fontSize * 8,
        align: 'right',
        render: v => percentFormat(v)
      },
      {
        ...this.getColumeProps('浮動租金計費基礎', 'rentWithTax', numberSort, sortField, sortOrder),
        width: padding + fontSize * 6,
        render: v => valueToName(withTaxList, v)
      },
      {
        title: '操作',
        key: 'action',
        fixed: 'right',
        width: padding + fontSize * 2,
        render: (text, record) => (
          <span>
            <Link to={`/PvMaintain/${record.id}`}>查看</Link>
          </span>
        )
      }
    ];

    const totalWidth = columns.reduce((total, elem) => {
      return total + elem.width;
    }, 0);

    return (
      <div>
        <Header
          style={{
            background: '#fff',
            padding: 0,
            textAlign: 'center',
            fontSize: '2em'
          }}
        >
          查詢電廠資料
        </Header>
        <Content style={{ margin: '24px 16px 0', overflow: 'initial' }}>
          <Card style={{ width: '100%' }}>
            <Table
              dataSource={this.state.dataSource}
              columns={columns}
              defaultPageSize={5}
              pagination={this.state.pagination}
              scroll={{ x: totalWidth }}
              onChange={this.onChange}
            />
          </Card>
        </Content>
      </div>
    );
  }
}

export default PvQuery;
