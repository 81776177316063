import React, { Component } from 'react';
import _ from 'lodash';
import axios from 'axios';
import BigNumber from 'bignumber.js';
import styled from 'styled-components';
//
import HoldingCreateDialog from './HoldingCreateDialog';
import HoldingDeleteDialog from './HoldingDeleteDialog';
import HoldingModifyDialog from './HoldingModifyDialog';
import { Table, Button, Icon, message, Row, Col } from 'antd';
//
import { getToken } from '../../utils/setting';

/**
 * 共用變數宣告
 */
const BN = BigNumber.clone({ DECIMAL_PLACES: 16, ROUNDING_MODE: 1 });

/**
 * 自訂樣式元件
 */
const Amount = styled.span`
  margin-left: 20px;
`;

/**
 * 主要元件
 */
class PvHolding extends Component {
  constructor(props) {
    super(props);
    //props.id 電廠代碼

    this.state = {
      amount: {
        total: BN(0),
        dispatched: BN(0),
        remained: BN(0),
      },
      dataSource: [],
      showCreater: false,
      showModify: false,
      showDelete: false,
      record: null,
    };
  }

  componentDidMount() {
    this.loadAmount();
    this.loadData();
  }

  loadAmount() {
    axios
      .get(`/api/holding/amount/${this.props.id}`, { headers: { Authorization: getToken() } })
      .then((res) => {
        if (res && res.data && res.data.status) {
          const data = (res.data.result.amount || [])[0];
          const amount = { ...this.state.amount };

          amount.total = BN(data.totalAmount);
          amount.dispatched = BN(data.assetAmount);
          amount.remained = amount.total.minus(amount.dispatched);

          this.setState({ amount: amount });
        } else {
          message.error(res.data.msg);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }

  loadData() {
    axios
      .get(`/api/holding/all/${this.props.id}`, { headers: { Authorization: getToken() } })
      .then((res) => {
        //retrun when error occured.
        if (!res || !res.data || !res.data.status) {
          message.error(res.data.msg);
          return;
        }

        //
        let data = res.data.result.holding || [];

        const summary = _.chain(data)
          .groupBy('userId')
          .map((items, key) => ({
            userId: BN(key).toNumber(),
            count: items.length,
            totalAmount: _.sumBy(items, 'amount'),
          }))
          .value();

        let currentUserId = '';
        data = _.map(data, (m, mIdx) => {
          m.key = mIdx;

          if (m.userId !== currentUserId) {
            const summaryItem = summary.find((f) => f.userId === m.userId);
            m.totalAmount = summaryItem.totalAmount;
            m.rowSpan = summaryItem.count;

            currentUserId = m.userId;
          } else {
            m.totalAmount = 0;
            m.ratio = 0;
            m.rowSpan = 0;
          }

          return m;
        });

        this.setState({ dataSource: data });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  handleOpenCreater = () => {
    this.setState({ showCreater: true, record: null });
  };

  handleCreate = (saveData) => {
    if (_.isEmpty(saveData)) {
      message.warn('無資料可新增');
      return;
    }

    saveData.pvId = this.props.id;
    axios
      .post(`/api/holding/create`, saveData, { headers: { Authorization: getToken() } })
      .then((res) => {
        if (res.data.status) {
          this.setState({ showCreater: false });
          this.loadAmount();
          this.loadData();
        } else {
          message.error(res.data.msg);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  handleOpenModify = (record) => () => {
    this.setState({ showModify: true, record: record });
  };

  handleModify = (saveData) => {
    if (_.isEmpty(saveData)) {
      message.warn('無資料可更新');
      return;
    }

    saveData.pvId = this.state.record.pvId;
    saveData.userId = this.state.record.userId;
    axios
      .post(`/api/holding/modify`, saveData, { headers: { Authorization: getToken() } })
      .then((res) => {
        if (res.data.status) {
          this.setState({ showModify: false });
          this.loadAmount();
          this.loadData();
        } else {
          message.error(res.data.msg);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  handleOpenDelete = (record) => () => {
    this.setState({ showDelete: true, record: record });
  };

  handleDelete = () => {
    if (_.isEmpty(this.state.record)) {
      message.warn('無資料可刪除');
      return;
    }

    const delData = {
      userId: this.state.record.userId,
      pvId: this.state.record.pvId,
      period: this.state.record.period,
      contractDate: this.state.record.contractDate,
    };

    axios
      .post(`/api/holding/delete`, delData, { headers: { Authorization: getToken() } })
      .then((res) => {
        if (res.data.status) {
          this.setState({ showDelete: false });
          this.loadAmount();
          this.loadData();
        } else {
          message.error(res.data.msg);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  render() {
    const columns = [
      {
        title: '持有者',
        dataIndex: 'userName',
        key: 'userName',
        align: 'center',
        render: (value, record) => ({ children: value, props: { rowSpan: record.rowSpan } }),
      },
      {
        title: '期數',
        dataIndex: 'period',
        key: 'period',
      },
      {
        title: '合約日',
        dataIndex: 'contractDate',
        key: 'contractDate',
      },
      {
        title: '到期日',
        dataIndex: 'expiryDate',
        key: 'expiryDate',
      },
      {
        title: '容量',
        dataIndex: 'capacity',
        key: 'capacity',
        render: (value) => BN(value).toFormat(),
      },
      {
        title: '持有數量',
        dataIndex: 'amount',
        key: 'amount',
        render: (value) => BN(value).toFormat(),
      },
      {
        title: '總計',
        dataIndex: 'totalAmount',
        key: 'totalAmount',
        align: 'center',
        render: (value, record) => ({ children: BN(value).toFormat(), props: { rowSpan: record.rowSpan } }),
      },
      {
        title: '持有百分比',
        dataIndex: 'ratio',
        key: 'ratio',
        align: 'center',
        render: (value, record) => ({
          children:
            BN(record.totalAmount)
              .div(this.state.amount.total || 0)
              .dp(5)
              .times(100)
              .toFormat() + ' %',
          props: { rowSpan: record.rowSpan },
        }),
      },
      {
        title: '操作',
        key: 'action',
        align: 'center',
        width: 210,
        render: (value, record) => (
          <Row type="flex" gutter={[8, 0]} justify="center">
            <Col>
              <Button icon="edit" onClick={this.handleOpenModify(record)}>
                編輯
              </Button>
            </Col>
            <Col>
              <Button icon="delete" onClick={this.handleOpenDelete(record)}>
                刪除
              </Button>
            </Col>
          </Row>
        ),
      },
    ];

    return (
      <div>
        <div style={{ marginBottom: '1em' }}>
          <Button onClick={this.handleOpenCreater}>
            <Icon type="edit" />
            新建持有
          </Button>
          <Amount>總量: {this.state.amount.total.toFormat()}</Amount>
          <Amount>已分配: {this.state.amount.dispatched.toFormat()}</Amount>
          <Amount>剩餘量: {this.state.amount.remained.toFormat()}</Amount>
        </div>
        <Table dataSource={this.state.dataSource} columns={columns} pagination={false} />
        {this.state.showCreater && (
          <HoldingCreateDialog
            id={this.props.id}
            amount={this.state.amount}
            onCreate={this.handleCreate}
            onHide={() => this.setState({ showCreater: false })}
          />
        )}
        {this.state.showModify && (
          <HoldingModifyDialog
            amount={this.state.amount}
            record={this.state.record}
            onModify={this.handleModify}
            onHide={() => this.setState({ showModify: false })}
          />
        )}
        {this.state.showDelete && (
          <HoldingDeleteDialog
            title="刪除持有"
            record={this.state.record}
            onDelete={this.handleDelete}
            onHide={() => this.setState({ showDelete: false })}
          />
        )}
      </div>
    );
  }
}

export default PvHolding;
