import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import styled from 'styled-components';
import moment from 'moment';
import { Table, Button, message, Row, Col, Tabs, Card, Spin } from 'antd';
import BillPreparingModifyDialog from '../PvMaintain/BillPreparingModifyDialog';
import BillPreparingConfirmDialog from '../PvMaintain/BillPreparingConfirmDialog';
import key from '../../config/key';
import { numberFormat } from '../Utils/Format';
import { getToken } from '../../utils/setting';
//Export excel
import xlsx from 'xlsx';
const TabPane = Tabs.TabPane;

class PvBillPreparing extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dataSource: [],
      dataSourceUnSettle: [],
      selectedRowKeysUnSettle: [],
      dataSourceChecking: [],
      selectedRowKeysChecking: [],
      dataSourceSnyc: [],
      sellPrice: null,
      showModify: false,
      showConfirm: false,
      record: {},
      loading: false,
    };
  }

  componentDidMount() {
    this.loadData();
    //this.loadSellPrice();
    this.loadSyncData();
  }

  loadData() {
    return axios
      .get(`/api/billPreparing/settle`, { headers: { Authorization: getToken() } })
      .then((res) => {
        if (res.data.status) {
          const dataSource = res.data.result.billsPreparing;
          const listUnSettle = dataSource.filter((d) => d.status === null);
          const listChecking = dataSource.filter((d) => d.status === 1);
          this.setState({
            dataSourceUnSettle: listUnSettle,
            dataSourceChecking: listChecking,
            selectedRowKeysUnSettle: [],
            selectedRowKeysChecking: [],
          });
        } else {
          message.error(res.data.msg);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  loadSellPrice = (v) => {
    return axios
      .get(`/api/billPreparing/sellPrice/${v}`, {
        headers: { Authorization: getToken() },
      })
      .then((res) => {
        if (res.data.status) {
          this.setState({ sellPrice: res.data.result.sellPrice });
        } else {
          message.error(res.data.msg);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  loadSyncData() {
    return axios
      .get(`/api/billSync/list`, { headers: { Authorization: getToken() } })
      .then((res) => {
        if (res.data.status) {
          this.setState({ dataSourceSnyc: res.data.result.bills });
        } else {
          message.error(res.data.msg);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  handleOpenModify = (record) => {
    this.loadSellPrice(record.pvId);
    this.setState({ showModify: true, record });
  };

  handleExport() {
    if (this.state.dataSourceChecking && this.state.dataSourceChecking.length > 0) {
      const settlementMonth = this.state.dataSourceChecking[0].settlementMonth;
      axios
        .get(key.reportURL + '/api/v1/report/getLandscapePdf', {
          //headers: { 'Authorization': this.props.auth.token },
          responseType: 'blob',
          params: {
            page: 'BillSummaryForOwner/' + settlementMonth,
          },
        })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('target', '_blank');
          document.body.appendChild(link);
          link.click();
        });

      axios
        .get(key.reportURL + '/api/v1/report/getLandscapePdf', {
          //headers: { 'Authorization': this.props.auth.token },
          responseType: 'blob',
          params: {
            page: 'BillPayment/' + settlementMonth,
          },
        })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('target', '_blank');
          document.body.appendChild(link);
          link.click();
        });

      axios
        .get(key.reportURL + '/api/v1/report/getLandscapePdf', {
          //headers: { 'Authorization': this.props.auth.token },
          responseType: 'blob',
          params: {
            page: 'BillInvoice/' + settlementMonth,
          },
        })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('target', '_blank');
          document.body.appendChild(link);
          link.click();
        });
      return axios
        .get(`/api/billPreparing/splitAccounts`, { headers: { Authorization: getToken() } })
        .then((res) => {
          if (res.data.status) {
            const dataRows = res.data.result.dataRows;
            if (dataRows.length > 0) {
              let array = [
                [
                  '專案代碼',
                  '案廠名稱',
                  '計費啟始日',
                  '計費結束日',
                  '計費天數',
                  '計費度數',
                  '發電金額',
                  '補付(扣)電費',
                  '營業稅',
                  '含稅電費',
                  '本期電表租費',
                  '電表租費收(退)',
                  '電錶租費',
                  '收益總計',
                  '租金費用',
                  '營運維修費',
                  '索拉服務費',
                  '保險費用',
                  '貸款本息費用',
                ],
              ];
              dataRows.forEach((d) => {
                array.push([
                  d.projectCode,
                  d.name,
                  d.rangeBegin,
                  d.rangeEnd,
                  d.days,
                  d.totalPower,
                  d.powerAmount,
                  d.deductPowerAmount,
                  d.salesTax,
                  d.incomeWithTax,
                  d.powerRent,
                  d.makeupPowerRent,
                  d.meterRentCost,
                  d.profit,
                  d.rentCost,
                  d.operateCost,
                  d.serviceCostFloat,
                  d.insuranceCost,
                  d.loan,
                ]);
              });

              let sheet = xlsx.utils.aoa_to_sheet(array);
              sheet['!cols'] = [
                { wch: 9 },
                { wch: 35 },
                { wch: 11 },
                { wch: 11 },
                { wch: 9 },
                { wch: 9 },
                { wch: 9 },
                { wch: 13 },
                { wch: 9 },
                { wch: 9 },
                { wch: 13 },
                { wch: 13 },
                { wch: 9 },
                { wch: 9 },
                { wch: 9 },
                { wch: 11 },
                { wch: 11 },
                { wch: 9 },
                { wch: 13 },
              ];
              // 構造workBook
              let workBook = {
                SheetNames: ['電費單拆帳明細'],
                Sheets: {
                  電費單拆帳明細: sheet,
                },
              };
              // 將workBook寫入檔案
              xlsx.writeFile(workBook, moment().format('YYYYMMDD') + '電費單拆帳明細.xlsx');
            } else {
              message.error('查無電費拆帳明細');
            }
          } else {
            message.error(res.data.msg);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      message.error('查無審核中電費資料');
    }
  }

  handleModify = (v) => {
    return axios
      .post(
        `/api/billPreparing/modify`,
        {
          id: this.state.record.id,
          pvId: this.props.id,
          rangeBegin: v.range[0].format('YYYY-MM-DD'),
          rangeEnd: v.range[1].format('YYYY-MM-DD'),
          totalPower: v.totalPower,
          powerAmount: v.powerAmount,
          deductAmount: v.deductAmount,
          salesTax: Math.round((Math.round(v.totalPower * v.sellPrice) + v.deductAmount) * 0.05),
          incomeWithTax: v.incomeWithTax,
          powerRent: v.powerRent,
          makeupRent: v.makeupRent,
          meterRentCost: v.meterRentCost,
        },
        { headers: { Authorization: getToken() } }
      )
      .then((res) => {
        if (res.data.status) {
          this.setState({ showModify: false });
          message.info('修改電費單成功');
          this.loadData();
        } else {
          message.error(res.data.msg);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  handleDistribute = (id) => {
    return axios
      .post(
        `/api/billPreparing/batchDistribute`,
        {
          ids: [id],
        },
        { headers: { Authorization: getToken() } }
      )
      .then((res) => {
        if (res.data.status) {
          this.loadData();
        } else {
          message.error(res.data.msg);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  handleCancelDistribute = (id) => {
    return axios
      .post(
        `/api/billPreparing/batchCancelDistribute`,
        {
          ids: [id],
          status: null,
        },
        { headers: { Authorization: getToken() } }
      )
      .then((res) => {
        if (res.data.status) {
          this.loadData();
        } else {
          message.error(res.data.msg);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  handleReport = (id) => {
    //TODO: show loading report
    axios
      .get(key.reportURL + '/api/v1/report/getPdf', {
        //headers: { 'Authorization': this.props.auth.token },
        responseType: 'blob',
        params: {
          page: 'BillPreparingReport/' + id,
        },
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('target', '_blank');
        document.body.appendChild(link);
        link.click();
      });
  };

  handleReview = (id) => {
    return axios
      .post(
        `/api/billPreparing/updateStatus`,
        {
          id,
          status: 1,
        },
        { headers: { Authorization: getToken() } }
      )
      .then((res) => {
        if (res.data.status) {
          this.loadData();
        } else {
          message.error(res.data.msg);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  handleCancelReview = (id) => {
    return axios
      .post(
        `/api/billPreparing/updateStatus`,
        {
          id,
          status: 0,
        },
        { headers: { Authorization: getToken() } }
      )
      .then((res) => {
        if (res.data.status) {
          this.loadData();
        } else {
          message.error(res.data.msg);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  handleOpenConfirm = (record) => {
    this.setState({ showConfirm: true, record });
  };

  handleConfirm = (v) => {
    return axios
      .post(
        `/api/billPreparing/confirm`,
        {
          id: this.state.record.id,
          pvId: this.state.record.pvId,
          file: this.state.record.file,
          rangeBegin: v.range[0].format('YYYY-MM-DD'),
          rangeEnd: v.range[1].format('YYYY-MM-DD'),
          totalPower: v.totalPower,
          powerAmount: this.state.record.powerAmount,
          deductAmount: this.state.record.deductPowerAmount,
          salesTax: this.state.record.salesTax,
          incomeWithTax: v.incomeWithTax,
          powerRent: this.state.record.powerRent,
          makeupRent: this.state.record.makeupPowerRent,
          meterRentCost: v.meterRentCost,
          profit: v.profit,
          rentCostFixed: v.rentCostFixed,
          rentCostFloat: v.rentCostFloat,
          operateCostFixed: v.operateCostFixed,
          operateCostFloat: v.operateCostFloat,
          serviceCostFloat: v.serviceCostFloat,
          insuranceCost: v.insuranceCost,
          loanCost: v.loanCost,
          loanFeeCost: v.loanFeeCost,
          inTrust: v.inTrust,
        },
        { headers: { Authorization: getToken() } }
      )
      .then((res) => {
        if (res.data.status) {
          this.setState({ showConfirm: false });
          message.info('建立電費分潤成功');
          this.loadData();
        } else {
          message.error(res.data.msg);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  handleSubmitDistribute() {
    return axios
      .post(
        `/api/billPreparing/batchDistribute`,
        {
          ids: this.state.selectedRowKeysUnSettle,
        },
        { headers: { Authorization: getToken() } }
      )
      .then((res) => {
        if (res.data.status) {
          this.loadData();
          if (res.data.result) {
            const { dataRows, settlement } = res.data.result;
            let successMsg = '';
            let failMsg = '';
            if (dataRows) {
              dataRows.forEach((d) => {
                if (d.status === '1') {
                  successMsg += ',[' + d.id + ']';
                } else {
                  failMsg += ',[' + d.id + ']' + d.error;
                }
              });
            }
            if (successMsg.length > 1) {
              message.info('結算成功：' + successMsg.substring(1));
            }
            if (failMsg.length > 1) {
              message.error('結算失敗：' + failMsg.substring(1));
            }
            if (settlement && settlement.status === '1') {
              message.info('產生結算資料成功');
            } else {
              message.error('產生結算資料失敗');
            }
          }
        } else {
          message.error(res.data.msg);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  handleSubmitCancelDistribute() {
    return axios
      .post(
        `/api/billPreparing/batchCancelDistribute`,
        {
          ids: this.state.selectedRowKeysChecking,
          status: null,
        },
        { headers: { Authorization: getToken() } }
      )
      .then((res) => {
        if (res.data.status) {
          this.loadData();
          if (res.data.result) {
            const { dataRows, settlement } = res.data.result;
            let successMsg = '';
            let failMsg = '';
            if (dataRows) {
              dataRows.forEach((d) => {
                if (d.status === '1') {
                  successMsg += ',[' + d.id + ']';
                } else {
                  failMsg += ',[' + d.id + ']' + d.error;
                }
              });
            }
            if (successMsg.length > 1) {
              message.info('更新成功：' + successMsg.substring(1));
            }
            if (failMsg.length > 1) {
              message.error('更新失敗：' + failMsg.substring(1));
            }
            if (settlement && settlement.status === '1') {
              message.info('產生結算資料成功');
            } else {
              message.error('產生結算資料失敗');
            }
          }
        } else {
          message.error(res.data.msg);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  handleSubmitChecking() {
    return axios
      .post(
        `/api/billPreparing/batchConfirm`,
        {
          ids: this.state.selectedRowKeysChecking,
        },
        { headers: { Authorization: getToken() } }
      )
      .then((res) => {
        if (res.data.status) {
          this.loadData();
          if (res.data.result) {
            const { dataRows } = res.data.result;
            let successMsg = '';
            let failMsg = '';
            if (dataRows) {
              dataRows.forEach((d) => {
                if (d.status === '1') {
                  successMsg += ',[' + d.id + ']';
                } else {
                  failMsg += ',[' + d.id + ']' + d.error;
                }
              });
            }
            if (successMsg.length > 1) {
              message.info('更新成功：' + successMsg.substring(1));
            }
            if (failMsg.length > 1) {
              message.error('更新失敗：' + failMsg.substring(1));
            }
          }
        } else {
          message.error(res.data.msg);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  handleSyncMIS = (settlementMonth) => {
    this.setState({ loading: true });
    return axios
      .post(
        `/api/billSync/synchronize`,
        { settlementMonth: settlementMonth },
        { headers: { Authorization: getToken() } }
      )
      .then((res) => {
        if (res.data.status) {
          this.loadSyncData();
          this.setState({ loading: false });
          message.info('同步完成');
        } else {
          this.setState({ loading: false });
          message.error(res.data.msg);
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        console.error(error);
      });
  };

  render() {
    const padding = 32;
    const fontSize = 14;
    const { selectedRowKeysUnSettle, selectedRowKeysChecking } = this.state;
    const rowSelectionUnSettle = {
      selectedRowKeys: selectedRowKeysUnSettle,
      onChange: (selectedRowKeys) => {
        this.setState({
          selectedRowKeysUnSettle: selectedRowKeys,
        });
      },
      getCheckboxProps: (record) => ({
        disabled: Number(record.ownRatio) !== 0, // Column configuration not to be checked
      }),
    };
    const rowSelectionChecking = {
      selectedRowKeys: selectedRowKeysChecking,
      onChange: (selectedRowKeys) => {
        this.setState({
          selectedRowKeysChecking: selectedRowKeys,
        });
      },
      getCheckboxProps: (record) => ({
        disabled: Number(record.ownRatio) !== 0, // Column configuration not to be checked
      }),
    };
    const columns = [
      {
        title: '序號',
        dataIndex: 'id',
        width: padding + fontSize * 3,
        key: 'id',
        fixed: 'left',
      },
      {
        title: '電廠名稱',
        dataIndex: 'name',
        width: padding + fontSize * 12,
        key: 'name',
        fixed: 'left',
      },
      {
        title: '檔案',
        dataIndex: 'file',
        key: 'file',
        width: padding + fontSize * 4,
        align: 'center',
        fixed: 'left',
        render: (text, record) => {
          if (record.file) {
            return (
              <Link to={`/api/upload/file/${record.file}`} target="_blank">
                查看
              </Link>
            );
          } else {
            return null;
          }
        },
      },
      {
        title: '計費起始日',
        dataIndex: 'rangeBegin',
        width: padding + fontSize * 6,
        key: 'rangeBillBegin',
        fixed: 'left',
      },
      {
        title: '計費結束日',
        dataIndex: 'rangeEnd',
        width: padding + fontSize * 6,
        key: 'rangeBillEnd',
        fixed: 'left',
        render: (v) => moment(v).subtract(1, 'days').format('YYYY-MM-DD'),
      },
      {
        title: '抄表起始日',
        dataIndex: 'rangeBegin',
        width: padding + fontSize * 6,
        key: 'rangeBegin',
      },
      {
        title: '抄表結束日',
        dataIndex: 'rangeEnd',
        width: padding + fontSize * 6,
        key: 'rangeEnd',
      },
      {
        title: '發電度數',
        dataIndex: 'totalPower',
        width: padding + fontSize * 6,
        key: 'totalPower',
        align: 'right',
        render: (v) => numberFormat({ value: v, postfix: '度' }),
      },
      {
        title: '發電金額',
        dataIndex: 'powerAmount',
        width: padding + fontSize * 5,
        key: 'powerAmount',
        align: 'right',
        render: (v) => numberFormat({ value: v, postfix: '元' }),
      },
      {
        title: '補付(扣)電費',
        dataIndex: 'deductPowerAmount',
        width: padding + fontSize * 6,
        key: 'deductAmount',
        align: 'right',
        render: (v) => numberFormat({ value: v, postfix: '元' }),
      },
      {
        title: '營業稅',
        dataIndex: 'salesTax',
        width: padding + fontSize * 6,
        key: 'salesTax',
        align: 'right',
        render: (v) => numberFormat({ value: v, postfix: '元' }),
      },
      {
        title: '總電費(含稅)',
        dataIndex: 'incomeWithTax',
        width: padding + fontSize * 6,
        key: 'incomeWithTax',
        align: 'right',
        render: (v) => numberFormat({ value: v, postfix: '元' }),
      },
      {
        title: '本期電表租費',
        dataIndex: 'powerRent',
        width: padding + fontSize * 6,
        key: 'powerRent',
        align: 'right',
        render: (v) => numberFormat({ value: v, postfix: '元' }),
      },
      {
        title: '電表租費收(退)',
        dataIndex: 'makeupPowerRent',
        width: padding + fontSize * 7,
        key: 'makeupRent',
        align: 'right',
        render: (v) => numberFormat({ value: v, postfix: '元' }),
      },
      {
        title: '電表租費',
        dataIndex: 'meterRentCost',
        width: padding + fontSize * 6,
        key: 'meterRentCost',
        align: 'right',
        render: (v) => numberFormat({ value: v, postfix: '元' }),
      },
      {
        title: '收益權分潤',
        dataIndex: 'profit',
        width: padding + fontSize * 6,
        key: 'profit',
        align: 'right',
        render: (v) => numberFormat({ value: v, postfix: '元' }),
      },
      {
        title: '租金(固定)',
        dataIndex: 'rentCostFixed',
        width: padding + fontSize * 6,
        key: 'rentCostFixed',
        align: 'right',
        render: (v) => numberFormat({ value: v, postfix: '元' }),
      },
      {
        title: '租金(浮動)',
        dataIndex: 'rentCostFloat',
        width: padding + fontSize * 6,
        key: 'rentCostFloat',
        align: 'right',
        render: (v) => numberFormat({ value: v, postfix: '元' }),
      },
      {
        title: '營運費(固定)',
        dataIndex: 'operateCostFixed',
        width: padding + fontSize * 6,
        key: 'operateCostFixed',
        align: 'right',
        render: (v) => numberFormat({ value: v, postfix: '元' }),
      },
      {
        title: '營運費(浮動)',
        dataIndex: 'operateCostFloat',
        width: padding + fontSize * 6,
        key: 'operateCostFloat',
        align: 'right',
        render: (v) => numberFormat({ value: v, postfix: '元' }),
      },
      {
        title: '服務費',
        dataIndex: 'serviceCostFloat',
        width: padding + fontSize * 6,
        key: 'serviceCostFloat',
        align: 'right',
        render: (v) => numberFormat({ value: v, postfix: '元' }),
      },
      {
        title: '保險費',
        dataIndex: 'insuranceCost',
        width: padding + fontSize * 6,
        key: 'insuranceCost',
        align: 'right',
        render: (v) => numberFormat({ value: v, postfix: '元' }),
      },
      {
        title: '貸款費',
        dataIndex: 'loanCost',
        width: padding + fontSize * 6,
        key: 'loanCost',
        align: 'right',
        render: (v) => numberFormat({ value: v, postfix: '元' }),
      },
      {
        title: '貸款手續費',
        dataIndex: 'loanFeeCost',
        width: padding + fontSize * 6,
        key: 'loanFeeCost',
        align: 'right',
        render: (v) => numberFormat({ value: v, postfix: '元' }),
      },
      {
        title: '狀態',
        dataIndex: 'status',
        width: padding + fontSize * 4,
        key: 'status',
        align: 'left',
        fixed: 'right',
        render: (v) => {
          switch (v) {
            case null:
              return '未結算';
            case 0:
              return '待審核';
            case 1:
              return '審核中';
            case 2:
              return '已核准';
            default:
              return '未知';
          }
        },
      },
      {
        title: '異常',
        dataIndex: 'ownRatio',
        width: padding + fontSize * 4,
        key: 'ownRatio',
        align: 'left',
        fixed: 'right',
        render: (value, record) => {
          return <div>{Number(record.ownRatio) !== 0 ? '分配異常' : ''}</div>;
        },
      },
      {
        title: '操作',
        width: padding + fontSize * 15,
        key: 'action',
        fixed: 'right',
        render: (text, record) => {
          let operations = [];
          switch (record.status) {
            case null:
              operations.push(
                <Button
                  key={1}
                  onClick={() => {
                    this.handleOpenModify(record);
                  }}
                >
                  {'修改'}
                </Button>
              );
              operations.push(
                <Button
                  key={2}
                  onClick={() => {
                    this.handleDistribute(record.id);
                  }}
                  disabled={Number(record.ownRatio) !== 0}
                >
                  {'結算>'}
                </Button>
              );
              break;
            case 0:
              operations.push(
                <Button
                  key={1}
                  onClick={() => {
                    this.handleCancelDistribute(record.id);
                  }}
                >
                  {'<返回'}
                </Button>
              );
              operations.push(
                <Button
                  key={2}
                  onClick={() => {
                    this.handleReport(record.id);
                  }}
                >
                  {'報表'}
                </Button>
              );
              operations.push(
                <Button
                  key={3}
                  onClick={() => {
                    this.handleReview(record.id);
                  }}
                >
                  {'審核>'}
                </Button>
              );
              break;
            case 1:
              operations.push(
                <Button
                  key={1}
                  onClick={() => {
                    this.handleCancelDistribute(record.id);
                  }}
                >
                  {'<返回'}
                </Button>
              );
              operations.push(
                <Button
                  key={2}
                  onClick={() => {
                    this.handleOpenConfirm(record);
                  }}
                  disabled={Number(record.ownRatio) !== 0}
                >
                  {'審核完成>'}
                </Button>
              );
              break;
            default:
              break;
          }
          return <Operations>{operations}</Operations>;
        },
      },
    ];

    const totalWidth = columns.reduce((total, elem) => {
      return total + elem.width;
    }, 0);

    const syncColumns = [
      {
        title: '結算月份',
        dataIndex: 'settlementMonth',
        key: 'settlementMonth',
        width: padding + fontSize * 3,
      },
      {
        title: '檔案',
        dataIndex: 'files',
        key: 'files',
        width: padding + fontSize * 15,
        align: 'left',
        render: (value, record) => {
          if (value) {
            return (
              <Container>
                {value.files.map((item, i) => (
                  <Item key={i}>
                    <Link to={`/api/upload/file/${item.file}`} target="_blank">
                      {`${item.name}  `}
                    </Link>
                  </Item>
                ))}
              </Container>
            );
          } else {
            return null;
          }
        },
      },
      {
        title: '上次同步時間',
        dataIndex: 'updateDt',
        key: 'updateDt',
        width: padding + fontSize * 3,
        render: (v) => moment(v).format('YYYY-MM-DD HH:mm'),
      },
      {
        title: '同步註記',
        dataIndex: 'synchronized',
        key: 'synchronized',
        width: padding + fontSize * 3,
        render: (value, record) => {
          if (value) {
            return '成功';
          } else {
            return '失敗';
          }
        },
      },
      {
        title: '操作',
        width: padding + fontSize * 6,
        dataIndex: 'action',
        key: 'action',
        render: (value, record) => {
          return (
            <div>
              <Button
                onClick={() => {
                  this.handleSyncMIS(record.settlementMonth);
                }}
              >
                {'重新同步'}
              </Button>
            </div>
          );
        },
      },
    ];

    const totalWidthSync = syncColumns.reduce((total, elem) => {
      return total + elem.width;
    }, 0);

    return (
      <div>
        <Card style={{ width: '100%' }}>
          <Tabs defaultActiveKey="1">
            <TabPane tab="未結算" key="1">
              <Row type="flex" justify="end" gutter={[16, 16]}>
                <Col>
                  <Button
                    onClick={() => {
                      this.handleSubmitDistribute();
                    }}
                  >
                    批次結算
                  </Button>
                </Col>
              </Row>
              <Table
                rowSelection={{
                  type: 'checkbox',
                  ...rowSelectionUnSettle,
                }}
                dataSource={this.state.dataSourceUnSettle}
                columns={columns}
                pagination={false}
                scroll={{ x: totalWidth, y: 'calc(100vh - 353px)' }}
              />
            </TabPane>
            <TabPane tab="審核中" key="2">
              <Row type="flex" justify="end" gutter={[16, 16]}>
                <Col>
                  <Button
                    onClick={() => {
                      this.handleExport();
                    }}
                  >
                    匯出拆帳明細
                  </Button>
                </Col>
                <Col>
                  <Button
                    onClick={() => {
                      this.handleSubmitCancelDistribute();
                    }}
                  >
                    批次返回
                  </Button>
                </Col>
                <Col>
                  <Button
                    onClick={() => {
                      this.handleSubmitChecking();
                    }}
                  >
                    批次完成
                  </Button>
                </Col>
              </Row>
              <Table
                rowSelection={{
                  type: 'checkbox',
                  ...rowSelectionChecking,
                }}
                dataSource={this.state.dataSourceChecking}
                columns={columns}
                pagination={false}
                scroll={{ x: totalWidth, y: 'calc(100vh - 353px)' }}
              />
            </TabPane>
            <TabPane tab="同步MIS" key="3">
              <Spin spinning={this.state.loading}>
                <Row type="flex" justify="end" gutter={[16, 16]}>
                  <Col>
                    <Button
                      onClick={() => {
                        this.handleSyncMIS(null);
                      }}
                    >
                      同步MIS
                    </Button>
                  </Col>
                </Row>
                <Table
                  dataSource={this.state.dataSourceSnyc}
                  columns={syncColumns}
                  pagination={false}
                  scroll={{ x: totalWidthSync, y: 'calc(100vh - 353px)' }}
                />
              </Spin>
            </TabPane>
          </Tabs>
        </Card>
        {this.state.showModify ? (
          <BillPreparingModifyDialog
            sellPrice={this.state.sellPrice}
            data={this.state.record}
            onModify={this.handleModify}
            onHide={() => this.setState({ showModify: false })}
          />
        ) : null}
        {this.state.showConfirm ? (
          <BillPreparingConfirmDialog
            data={this.state.record}
            onConfirm={this.handleConfirm}
            onHide={() => this.setState({ showConfirm: false })}
          />
        ) : null}
      </div>
    );
  }
}

const Operations = styled.div`
  display: flex;
`;

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Item = styled.div`
  position: relative;
  margin-right: 1em;
  width: 10em;
`;

export default PvBillPreparing;
