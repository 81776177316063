import React, { Component } from 'react';
import axios from 'axios';
import key from '../config/key';
import { Link } from 'react-router-dom';
import { Layout, Table, Card, Button } from 'antd';
import { numberFormat } from './Utils/Format';
import { getToken } from '../utils/setting';
const { Header, Content } = Layout;

class UserQuery extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dataSource: []
    };
  }

  componentDidMount() {
    this.loadData();
  }

  loadData() {
    return axios
      .get(`/api/spv/all`, { headers: { Authorization: getToken() } })
      .then(res => {
        this.setState({ dataSource: res.data.spvList });
      })
      .catch(error => {
        console.error(error);
      });
  }

  handleReport = id => {
    //TODO: show loading report
    axios
      .get(key.reportURL + '/api/v1/report/getPng', {
        //headers: { 'Authorization': this.props.auth.token },
        responseType: 'blob',
        params: {
          page: 'InvestmentReport/' + id
        }
      })
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data], { type: 'image/png' }));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('target', '_blank');
        document.body.appendChild(link);
        link.click();
      });
  };

  render() {
    const padding = 32;
    const fontSize = 14;
    const columns = [
      {
        title: '名稱',
        dataIndex: 'name',
        key: 'name',
        fixed: 'left',
        width: padding + fontSize * 8
      },
      {
        title: '公司名稱',
        dataIndex: 'companyName',
        key: 'companyName',
        width: padding + fontSize * 12
      },
      {
        title: '地址',
        dataIndex: 'address',
        key: 'address',
        width: padding + fontSize * 20
      },
      {
        title: '出金帳戶',
        dataIndex: 'cashoutAccount',
        key: 'cashoutAccount',
        width: padding + fontSize * 20
      },
      {
        title: '帳戶餘額',
        dataIndex: 'sttw',
        key: 'sttw',
        width: padding + fontSize * 10,
        align: 'right',
        render: v => numberFormat({ value: v, postfix: '元' })
      },
      {
        title: '凍結餘額',
        dataIndex: 'lockSttw',
        key: 'lockSttw',
        width: padding + fontSize * 10,
        align: 'right',
        render: v => numberFormat({ value: v, postfix: '元' })
      },
      {
        title: '財務報告',
        key: 'report',
        fixed: 'right',
        width: padding + fontSize * 4,
        render: (text, record) => (
          <span>
            <Button
              onClick={() => {
                this.handleReport(record.id);
              }}
            >
              產生
            </Button>
          </span>
        )
      },
      {
        title: '操作',
        key: 'action',
        fixed: 'right',
        width: padding + fontSize * 4,
        render: (text, record) => (
          <span>
            <Link to={`/UserMaintain/${record.id}`}>維護</Link>
          </span>
        )
      }
    ];

    const totalWidth = columns.reduce((total, elem) => {
      return total + elem.width;
    }, 0);

    return (
      <div>
        <Header
          style={{
            background: '#fff',
            padding: 0,
            textAlign: 'center',
            fontSize: '2em'
          }}
        >
          查詢Asset帳號
        </Header>
        <Content style={{ margin: '24px 16px 0', overflow: 'initial' }}>
          <Card style={{ width: '100%' }}>
            <Table
              dataSource={this.state.dataSource}
              columns={columns}
              defaultPageSize={5}
              scroll={{ x: totalWidth }}
            />
          </Card>
        </Content>
      </div>
    );
  }
}

export default UserQuery;
