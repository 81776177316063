import React, { Component } from 'react';
import BigNumber from 'bignumber.js';
import axios from 'axios';
import styled from 'styled-components';
import { DatePicker, Icon, Input, Button, Modal, Tooltip, Select, message, Row, Col, Divider } from 'antd';
import { getToken } from '../../utils/setting';
const Option = Select.Option;
const BN = BigNumber.clone({ DECIMAL_PLACES: 16, ROUNDING_MODE: 1 });

const CustomTooltip = styled(Tooltip)`
  margin: 0px 5px;
`;
const LabelCol = styled(Col)`
  text-align: right;
  padding-right: 10px;
  color: rgba(0, 0, 0, 0.85);
`;
const RedSpan = styled.span`
  color: #f00;
  margin: 0px 5px;
`;
const RedDiv = styled.div`
  color: #f00;
  font-size: 12px;
`;
const Hint = styled.div`
  color: #00f;
  font-size: 16px;
`;

class HoldingCreateDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userList: [],
      userId: null,
      userId_msg: '',
      period_text: '1',
      period: BN(1),
      period_msg: '',
      contractDate: null,
      contractDate_msg: '',
      expiryDate: null,
      expiryDate_msg: '',
      capacity_text: '0',
      capacity: BN(0),
      capacity_msg: '',
      amount_text: '0',
      amount: BN(0),
      amount_msg: '',
      remained: BN(this.props.amount.total).minus(this.props.amount.dispatched),
    };
  }

  componentDidMount() {
    this.loadUser();
  }

  loadUser = () => {
    axios
      .get(`/api/holding/availableUser/${this.props.id}`, { headers: { Authorization: getToken() } })
      .then((res) => {
        if (res.data.status) {
          this.setState({ userList: res.data.result.users });
        } else {
          message.error(res.data.msg);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  handleInputChange = (name) => (e) => {
    let value = e && e.target ? e.target.value : e;
    let remained = null;
    let msg = '';
    let updateNumberField = true;
    let updateRemainedField = false;

    switch (name) {
      case 'period':
        value = value.split(',').join('');
        if (isNaN(value)) {
          value = BN(1);
          msg = '請輸入數字';
        } else {
          value = BN(value || 1);
        }
        break;
      case 'capacity':
        value = value.split(',').join('');
        if (isNaN(value)) {
          value = BN(0);
          msg = '請輸入數字';
        } else {
          value = BN(value || 0);
        }
        break;
      case 'amount':
        value = value.split(',').join('');
        if (isNaN(value)) {
          value = BN(0);
          msg = '請輸入數字';
        } else {
          value = BN(value || 0);
        }

        remained = BN(this.props.amount.total).minus(this.props.amount.dispatched).minus(value);
        updateRemainedField = true;
        if (remained.lt(0)) msg = '剩餘數量不足';
        break;
      default:
        updateNumberField = false;
        break;
    }

    if (updateNumberField) {
      if (updateRemainedField) {
        this.setState({
          [name]: value,
          [name + '_text']: value.toFormat(),
          [name + '_msg']: msg,
          remained: remained,
        });
      } else {
        this.setState({
          [name]: value,
          [name + '_text']: value.toFormat(),
          [name + '_msg']: msg,
        });
      }
    } else {
      this.setState({ [name]: value, [name + '_msg']: msg });
    }
  };

  handleInputNumberChange = (name) => (e) => {
    this.setState({ [name + '_text']: e.target.value });
  };

  handleCancel = () => {
    this.props.onHide && this.props.onHide();
  };

  handleCreate = () => {
    //判斷必填欄位是否都有填寫
    const errorMsgs = {};
    const requiredFields = ['period', 'contractDate', 'expiryDate', 'capacity', 'amount', 'userId'];
    const saveData = {
      period: this.state.period.toNumber(),
      contractDate: this.state.contractDate && this.state.contractDate.format('YYYY-MM-DD'),
      expiryDate: this.state.expiryDate && this.state.expiryDate.format('YYYY-MM-DD'),
      capacity: this.state.capacity.toNumber(),
      amount: this.state.amount.toNumber(),
      userId: this.state.userId,
    };
    requiredFields.forEach((field) => {
      if (!saveData[field]) {
        errorMsgs[field + '_msg'] = '請填寫欄位';
      }
    });
    if (Object.keys(errorMsgs).length > 0) {
      this.setState(errorMsgs);
      return;
    }

    //判斷持有數量與剩餘數量的關係
    if (this.state.amount_msg) {
      //amount_msg 應該為 '剩餘數量不足'
      return;
    }

    //儲存
    this.props.onCreate && this.props.onCreate(saveData);
  };

  render() {
    return (
      <Modal title="新增電廠持有" visible={true} footer={null} onCancel={this.handleCancel}>
        <Row gutter={[8, 10]}>
          <LabelCol span={8}>總發行量：</LabelCol>
          <Col span={16}>{this.props.amount.total.toFormat()}</Col>
          <LabelCol span={8}>已被持有：</LabelCol>
          <Col span={16}>{this.props.amount.dispatched.toFormat()}</Col>
          <LabelCol span={8}>剩餘數量：</LabelCol>
          <Col span={16}>{this.state.remained.toFormat()}</Col>
        </Row>
        <Divider />

        <Row gutter={[8, 30]}>
          <LabelCol span={8}>
            <RedSpan>*</RedSpan>
            <span>期數</span>
            <CustomTooltip title="此SPV買入/賣出電廠，是在第幾次增資">
              <Icon type="question-circle-o" />
            </CustomTooltip>
            <span>：</span>
          </LabelCol>
          <Col span={16}>
            <Input
              min={1}
              placeholder="請輸入期數"
              value={this.state.period_text}
              onChange={this.handleInputNumberChange('period')}
              onBlur={this.handleInputChange('period')}
              style={{ width: '100px' }}
            />
            <RedDiv>{this.state.period_msg}</RedDiv>
          </Col>

          <LabelCol span={8}>
            <RedSpan>*</RedSpan>
            <span>合約日</span>
            <CustomTooltip title="此SPV買入/賣出的電廠，是哪一天簽訂共同開發合約">
              <Icon type="question-circle-o" />
            </CustomTooltip>
            <span>：</span>
          </LabelCol>
          <Col span={16}>
            <DatePicker
              placeholder="請輸入合約日"
              value={this.state.contractDate}
              onChange={this.handleInputChange('contractDate')}
            />
            <RedDiv>{this.state.contractDate_msg}</RedDiv>
          </Col>

          <LabelCol span={8}>
            <RedSpan>*</RedSpan>
            <span>截止日</span>
            <CustomTooltip title="此SPV此期簽約，是哪一天到期">
              <Icon type="question-circle-o" />
            </CustomTooltip>
            <span>：</span>
          </LabelCol>
          <Col span={16}>
            <DatePicker
              placeholder="請輸入截止日"
              value={this.state.expiryDate}
              onChange={this.handleInputChange('expiryDate')}
            />
            <RedDiv>{this.state.expiryDate_msg}</RedDiv>
          </Col>

          <LabelCol span={8}>
            <RedSpan>*</RedSpan>
            <span>容量</span>
            <CustomTooltip title="此SPV買入/賣出電廠多少容量">
              <Icon type="question-circle-o" />
            </CustomTooltip>
            <span>：</span>
          </LabelCol>
          <Col span={16}>
            <Input
              placeholder="請輸入容量"
              value={this.state.capacity_text}
              onChange={this.handleInputNumberChange('capacity')}
              onBlur={this.handleInputChange('capacity')}
              style={{ width: '200px' }}
            />
            <RedDiv>{this.state.capacity_msg}</RedDiv>
          </Col>

          <LabelCol span={8}>
            <RedSpan>*</RedSpan>
            <span>持有數量</span>
            <CustomTooltip title="請參考收益權持有資料">
              <Icon type="question-circle-o" />
            </CustomTooltip>
            <span>：</span>
          </LabelCol>
          <Col span={16}>
            <Input
              min={0}
              placeholder="請輸入持有數量"
              value={this.state.amount_text}
              onChange={this.handleInputNumberChange('amount')}
              onBlur={this.handleInputChange('amount')}
              style={{ width: '200px' }}
            />
            <RedDiv>{this.state.amount_msg}</RedDiv>
          </Col>

          <LabelCol span={8}>
            <RedSpan>*</RedSpan>
            <span>持有者</span>
            <CustomTooltip title="請參考收益權持有資料">
              <Icon type="question-circle-o" />
            </CustomTooltip>
            <span>：</span>
          </LabelCol>
          <Col span={16}>
            <Select
              placeholder="請選擇持有者"
              value={this.state.userId}
              onChange={this.handleInputChange('userId')}
              style={{ width: '250px' }}
            >
              {this.state.userList.map((v, i) => (
                <Option key={i} value={v.id}>
                  {v.name}
                </Option>
              ))}
            </Select>
            <RedDiv>{this.state.userId_msg}</RedDiv>
          </Col>

          <Col span={24} style={{ textAlign: 'center' }}>
            <Hint>※ 請務必確認畫面填寫的資料，與合約資料一致</Hint>
          </Col>
          <Col span={24} style={{ textAlign: 'center' }}>
            <Button type="primary" size="large" onClick={this.handleCreate} icon="save">
              新增
            </Button>
          </Col>
        </Row>
      </Modal>
    );
  }
}

export default HoldingCreateDialog;
