import React from 'react';
import { Layout, Tabs, Card } from 'antd';
import PvInfo from './PvInfo';
import PvFile from './PvFile';
import PvImage from './PvImage';
import PvInsurance from './PvInsurance';
import PvLoanFee from './PvLoanFee';
import PvLoan from './PvLoan';
import PvBillPreparing from './PvBillPreparing';
import PvBill from './PvBill';
import PvHolding from './PvHolding';
const { Header, Content } = Layout;
const TabPane = Tabs.TabPane;

const PvMaintain = props => (
  <div>
    <Header
      style={{
        background: '#fff',
        padding: 0,
        textAlign: 'center',
        fontSize: '2em'
      }}
    >
      維護電廠資料: {props.match.params.pvId}
    </Header>
    <Content
      style={{
        margin: '24px 16px 0',
        overflow: 'initial',
        width: '100%'
      }}
    >
      <Card style={{ width: '100%' }}>
        <Tabs defaultActiveKey="1">
          <TabPane tab="基本資料" key="1">
            <PvInfo id={props.match.params.pvId} />
          </TabPane>
          <TabPane tab="文檔資料" key="2">
            <PvFile id={props.match.params.pvId} />
          </TabPane>
          <TabPane tab="實景照片" key="3">
            <PvImage id={props.match.params.pvId} />
          </TabPane>
          <TabPane tab="保險資料" key="4">
            <PvInsurance id={props.match.params.pvId} />
          </TabPane>
          <TabPane tab="貸款資料" key="5">
            <PvLoan id={props.match.params.pvId} />
          </TabPane>
          <TabPane tab="貸款手續費資料" key="6">
            <PvLoanFee id={props.match.params.pvId} />
          </TabPane>
          <TabPane tab="持有資料" key="7">
            <PvHolding id={props.match.params.pvId} />
          </TabPane>
          <TabPane tab="電費資料" key="8">
            <PvBillPreparing id={props.match.params.pvId} />
          </TabPane>
          <TabPane tab="分潤資料" key="9">
            <PvBill id={props.match.params.pvId} />
          </TabPane>
        </Tabs>
      </Card>
    </Content>
  </div>
);

export default PvMaintain;
