import React, { Component } from 'react';
import axios from 'axios';
import { Table, Button, Icon } from 'antd';
import EditDialog from '../Utils/EditDialog';
import { numberFormat } from '../Utils/Format';
import { getToken } from '../../utils/setting';

class UserInfo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: null,
      showEditor: false,
      editorRecord: null
    };
  }

  componentDidMount() {
    this.loadData();
  }

  loadData() {
    return axios
      .get(`/api/spv/info/${this.props.id}`, { headers: { Authorization: getToken() } })
      .then(res => {
        if (res.data.info.length === 1) {
          this.setState({ data: res.data.info[0] });
        } else {
          this.setState({ data: null });
        }
      })
      .catch(error => {
        console.error(error);
      });
  }

  handleEdit = record => {
    this.setState({
      showEditor: true,
      editorRecord: record
    });
  };

  handleNewValue = v => {
    const { key } = this.state.editorRecord;
    axios
      .post(`/api/spv/edit`, {
        id: this.props.id,
        field: key,
        value: v[key]
      }, { headers: { Authorization: getToken() } })
      .then(res => {
        if (res.data.result === 'ok') {
          let { data } = this.state;
          data[key] = v[key];
          this.setState({ data: data, showEditor: false });
        }
      })
      .catch(error => {
        console.error(error);
      });
  };

  render() {
    const d = this.state.data;
    if (d === null) {
      return <div>No data</div>;
    }

    const padding = 32;
    const fontSize = 14;
    const columns = [
      {
        title: '項目',
        dataIndex: 'colName',
        width: padding + fontSize * 6,
        key: 'colName'
      },
      {
        title: '資料值',
        dataIndex: 'colValue',
        key: 'colValue'
      },
      {
        title: '操作',
        key: 'action',
        width: padding + fontSize * 2,
        render: (text, record) => {
          if (record.editable) {
            return (
              <Button onClick={() => this.handleEdit(record)}>
                <Icon type="edit" />
                編輯
              </Button>
            );
          } else {
            return null;
          }
        }
      }
    ];

    let dataSource = [];
    dataSource.push({
      key: 'name',
      colName: '顯示名稱',
      colValue: d.name
    });
    dataSource.push({
      key: 'companyName',
      colName: '公司名稱',
      colValue: d.companyName,
      editable: true,
      type: 'text'
    });
    dataSource.push({
      key: 'address',
      colName: '地址',
      colValue: d.address,
      editable: true,
      type: 'text'
    });
    dataSource.push({
      key: 'cashoutAccount',
      colName: '出金帳戶',
      colValue: d.cashoutAccount,
      editable: true,
      type: 'text'
    });
    dataSource.push({
      key: 'sttw',
      colName: '帳戶餘額',
      colValue: numberFormat({ value: d.sttw, postfix: '元' })
    });
    dataSource.push({
      key: 'lockSttw',
      colName: '凍結餘額',
      colValue: numberFormat({ value: d.lockSttw, postfix: '元' })
    });

    return (
      <div>
        <Table dataSource={dataSource} columns={columns} pagination={false} />
        {this.state.showEditor ? (
          <EditDialog
            record={this.state.editorRecord}
            onNewValue={this.handleNewValue}
            onHide={() => this.setState({ showEditor: false })}
          />
        ) : null}
      </div>
    );
  }
}

export default UserInfo;
