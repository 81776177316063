import React, { Component } from 'react';
import moment from 'moment';
import { Form, Icon, DatePicker, InputNumber, Button, Modal, Tooltip, Radio } from 'antd';
const FormItem = Form.Item;
const RangePicker = DatePicker.RangePicker;

class BillPreparingConfirmDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: true
    };
  }

  handleCancel = () => {
    this.setState({
      visible: false
    });
    setTimeout(() => this.props.onHide(), 500);
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.onConfirm(values);
      }
    });
  };

  checkTotal = (rule, value, callback) => {
    const values = this.props.form.getFieldsValue();
    if (
      Math.abs(
        values.incomeWithTax -
          values.meterRentCost -
          values.profit -
          values.rentCostFixed -
          values.rentCostFloat -
          values.operateCostFixed -
          values.operateCostFloat -
          values.serviceCostFloat -
          values.insuranceCost -
          values.loanCost -
          values.loanFeeCost
      ) !== 0
    ) {
      callback('目前金額總計與總電費不合, 請再次確認');
      return;
    } else {
      this.props.form.setFields({
        profit: {
          value: values.profit,
          errors: null
        },
        rentCostFixed: {
          value: values.rentCostFixed,
          errors: null
        },
        rentCostFloat: {
          value: values.rentCostFloat,
          errors: null
        },
        operateCostFixed: {
          value: values.operateCostFixed,
          errors: null
        },
        operateCostFloat: {
          value: values.operateCostFloat,
          errors: null
        },
        serviceCostFloat: {
          value: values.serviceCostFloat,
          errors: null
        },
        insuranceCost: {
          value: values.insuranceCost,
          errors: null
        },
        loanCost: {
          value: values.loanCost,
          errors: null
        },
        loanFeeCost: {
          value: values.loanFeeCost,
          errors: null
        }
      });
    }
    callback();
  };

  render() {
    const { getFieldDecorator } = this.props.form;

    const formItemLayout = {
      labelCol: { span: 6 },
      wrapperCol: { span: 14 }
    };

    const tailFormItemLayout = {
      wrapperCol: {
        span: 8,
        offset: 6
      }
    };

    return (
      <Modal title="建立電費分潤" visible={this.state.visible} onCancel={this.handleCancel} footer={null}>
        <Form onSubmit={this.handleSubmit}>
          <FormItem {...formItemLayout} label={<span>抄表期間</span>}>
            {getFieldDecorator('range', {
              initialValue: [moment(this.props.data.rangeBegin), moment(this.props.data.rangeEnd)]
            })(<RangePicker placeholder={['本次抄表日', '下次抄表日']} disabled />)}
          </FormItem>
          <FormItem {...formItemLayout} label={<span>發電度數</span>}>
            {getFieldDecorator('totalPower', {
              initialValue: this.props.data.totalPower
            })(<InputNumber style={{ width: '200px' }} disabled />)}
            <span className="ant-form-text"> 度</span>
          </FormItem>
          <FormItem {...formItemLayout} label={<span>電費收入</span>}>
            {getFieldDecorator('incomeWithTax', {
              initialValue: this.props.data.incomeWithTax
            })(<InputNumber style={{ width: '200px' }} disabled />)}
            <span className="ant-form-text"> 元</span>
          </FormItem>
          <FormItem {...formItemLayout} label={<span>電表租費</span>}>
            {getFieldDecorator('meterRentCost', {
              initialValue: this.props.data.meterRentCost
            })(<InputNumber style={{ width: '200px' }} disabled />)}
            <span className="ant-form-text"> 元</span>
          </FormItem>
          <FormItem
            {...formItemLayout}
            label={
              <span>
                收益權分潤&nbsp;
                <Tooltip title="請參考審核後的電費拆帳報表上的收益總計">
                  <Icon type="question-circle-o" />
                </Tooltip>
              </span>
            }
          >
            {getFieldDecorator('profit', {
              initialValue: this.props.data.profit,
              rules: [
                {
                  type: 'number',
                  required: true,
                  message: '請輸入收益權分潤金額'
                },
                {
                  validator: this.checkTotal
                }
              ]
            })(<InputNumber style={{ width: '200px' }} />)}
            <span className="ant-form-text"> 元</span>
          </FormItem>
          <FormItem
            {...formItemLayout}
            label={
              <span>
                固定租金&nbsp;
                <Tooltip title="請參考審核後的電費拆帳報表上的租金費用">
                  <Icon type="question-circle-o" />
                </Tooltip>
              </span>
            }
          >
            {getFieldDecorator('rentCostFixed', {
              initialValue: this.props.data.rentCostFixed,
              rules: [
                {
                  type: 'number',
                  required: true,
                  message: '請輸入固定租金金額'
                },
                {
                  validator: this.checkTotal
                }
              ]
            })(<InputNumber style={{ width: '200px' }} min={0} />)}
            <span className="ant-form-text"> 元</span>
          </FormItem>
          <FormItem
            {...formItemLayout}
            label={
              <span>
                浮動租金&nbsp;
                <Tooltip title="請參考審核後的電費拆帳報表上的租金費用">
                  <Icon type="question-circle-o" />
                </Tooltip>
              </span>
            }
          >
            {getFieldDecorator('rentCostFloat', {
              initialValue: this.props.data.rentCostFloat,
              rules: [
                {
                  type: 'number',
                  required: true,
                  message: '請輸入浮動租金金額'
                },
                {
                  validator: this.checkTotal
                }
              ]
            })(<InputNumber style={{ width: '200px' }} min={0} />)}
            <span className="ant-form-text"> 元</span>
          </FormItem>
          <FormItem
            {...formItemLayout}
            label={
              <span>
                固定營運費&nbsp;
                <Tooltip title="請參考審核後的電費拆帳報表上的營運維修費">
                  <Icon type="question-circle-o" />
                </Tooltip>
              </span>
            }
          >
            {getFieldDecorator('operateCostFixed', {
              initialValue: this.props.data.operateCostFixed,
              rules: [
                {
                  type: 'number',
                  required: true,
                  message: '請輸入固定營運費金額'
                },
                {
                  validator: this.checkTotal
                }
              ]
            })(<InputNumber style={{ width: '200px' }} min={0} />)}
            <span className="ant-form-text"> 元</span>
          </FormItem>
          <FormItem
            {...formItemLayout}
            label={
              <span>
                浮動營運費&nbsp;
                <Tooltip title="請參考審核後的電費拆帳報表上的營運維修費">
                  <Icon type="question-circle-o" />
                </Tooltip>
              </span>
            }
          >
            {getFieldDecorator('operateCostFloat', {
              initialValue: this.props.data.operateCostFloat,
              rules: [
                {
                  type: 'number',
                  required: true,
                  message: '請輸入浮動營運費金額'
                },
                {
                  validator: this.checkTotal
                }
              ]
            })(<InputNumber style={{ width: '200px' }} min={0} />)}
            <span className="ant-form-text"> 元</span>
          </FormItem>
          <FormItem
            {...formItemLayout}
            label={
              <span>
                索拉服務費&nbsp;
                <Tooltip title="請參考審核後的電費拆帳報表上的索拉服務費">
                  <Icon type="question-circle-o" />
                </Tooltip>
              </span>
            }
          >
            {getFieldDecorator('serviceCostFloat', {
              initialValue: this.props.data.serviceCostFloat,
              rules: [
                {
                  type: 'number',
                  required: true,
                  message: '請輸入索拉服務費金額'
                },
                {
                  validator: this.checkTotal
                }
              ]
            })(<InputNumber style={{ width: '200px' }} min={0} />)}
            <span className="ant-form-text"> 元</span>
          </FormItem>
          <FormItem
            {...formItemLayout}
            label={
              <span>
                保險費用&nbsp;
                <Tooltip title="請參考審核後的電費拆帳報表上的保險費用">
                  <Icon type="question-circle-o" />
                </Tooltip>
              </span>
            }
          >
            {getFieldDecorator('insuranceCost', {
              initialValue: this.props.data.insuranceCost,
              rules: [
                {
                  type: 'number',
                  required: true,
                  message: '請輸入保險費用金額'
                },
                {
                  validator: this.checkTotal
                }
              ]
            })(<InputNumber style={{ width: '200px' }} min={0} />)}
            <span className="ant-form-text"> 元</span>
          </FormItem>
          <FormItem
            {...formItemLayout}
            label={
              <span>
                貸款費用&nbsp;
                <Tooltip title="請參考審核後的電費拆帳報表上的貸款費用">
                  <Icon type="question-circle-o" />
                </Tooltip>
              </span>
            }
          >
            {getFieldDecorator('loanCost', {
              initialValue: this.props.data.loanCost,
              rules: [
                {
                  type: 'number',
                  required: true,
                  message: '請輸入貸款費用金額'
                },
                {
                  validator: this.checkTotal
                }
              ]
            })(<InputNumber style={{ width: '200px' }} min={0} />)}
            <span className="ant-form-text"> 元</span>
          </FormItem>
          <FormItem
            {...formItemLayout}
            label={
              <span>
                貸款手續費用&nbsp;
                <Tooltip title="請參考審核後的電費拆帳報表上的貸款手續費用">
                  <Icon type="question-circle-o" />
                </Tooltip>
              </span>
            }
          >
            {getFieldDecorator('loanFeeCost', {
              initialValue: this.props.data.loanFeeCost,
              rules: [
                {
                  type: 'number',
                  required: true,
                  message: '請輸入貸款手續費用金額'
                },
                {
                  validator: this.checkTotal
                }
              ]
            })(<InputNumber style={{ width: '200px' }} min={0} />)}
            <span className="ant-form-text"> 元</span>
          </FormItem>
          <FormItem
            {...formItemLayout}
            label={
              <span>
                分潤方式&nbsp;
                <Tooltip title="請依照實際信託合約，判別此筆電費單是否為信託管理">
                  <Icon type="question-circle-o" />
                </Tooltip>
              </span>
            }
          >
            {getFieldDecorator('inTrust', {
              rules: [
                {
                  required: true,
                  message: '請選擇分潤方式'
                }
              ]
            })(
              <Radio.Group>
                <Radio value={false}>直接撥款</Radio>
                <Radio value={true}>信託管理</Radio>
              </Radio.Group>
            )}
          </FormItem>
          <FormItem {...tailFormItemLayout}>
            <Button type="primary" htmlType="submit">
              確定
            </Button>
          </FormItem>
        </Form>
      </Modal>
    );
  }
}

const WrappedBillPreparingConfirmDialogForm = Form.create()(BillPreparingConfirmDialog);

export default WrappedBillPreparingConfirmDialogForm;
