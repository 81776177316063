import React, { Component } from 'react';
import { Modal, Button, Divider, Row, Col } from 'antd';

class ConfirmDialog extends Component {
  handleDelete = () => {
    this.props.onDelete && this.props.onDelete();
  };

  handleCancel = () => {
    this.props.onHide && this.props.onHide();
  };

  render() {
    return (
      <Modal visible={true} footer={false} title={this.props.title} onCancel={this.handleCancel}>
        <Row type="flex">
          <Col span={24}>
            <h3>確認刪除 [{this.props.record.userName}] 的收益權持有</h3>
            <ul>
              <li>期數：{this.props.record.period}</li>
              <li>合約日：{this.props.record.contractDate}</li>
              <li>到期日：{this.props.record.expiryDate}</li>
              <li>容量：{this.props.record.capacity}</li>
              <li>持有數量：{this.props.record.amount}</li>
            </ul>
          </Col>
        </Row>
        <Divider />
        <Row type="flex" justify="end" gutter={[16, 0]}>
          <Col>
            <Button type="danger" size="large" icon="delete" onClick={this.handleDelete}>
              確認刪除
            </Button>
          </Col>
        </Row>
      </Modal>
    );
  }
}

export default ConfirmDialog;
