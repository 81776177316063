import React, { Component } from 'react';
import axios from 'axios';
import { sprintf } from 'sprintf-js';
import { Form, Input, Tooltip, Icon, Button, Layout, Modal, Card } from 'antd';
import { getToken } from '../../utils/setting';
const FormItem = Form.Item;
const { Content } = Layout;

class SetupSpvForm extends Component {
  constructor(props) {
    super(props);

    this.handleOk = this.handleOk.bind(this);
    this.handleCancel = this.handleCancel.bind(this);

    this.state = {
      existed: null,
      data: null,
      modalTitle: '',
      modalData: '',
      visible: false,
      confirmLoading: false,
      values: null
    };
  }

  componentDidMount() {
    this.checkExisted();
  }

  checkExisted = () => {
    return axios
      .get(`/api/spv/checkExisted/${this.props.id}`, { headers: { Authorization: getToken() } })
      .then(res => {
        this.setState({ existed: res.data.existed, data: res.data.content });
      })
      .catch(error => {
        console.error(error);
      });
  };

  showModal = values => {
    const data = (
      <div>
        <div>名稱: {values.name}</div>
        <div>域名: {values.domain}</div>
        <div>Email: {values.email}</div>
      </div>
    );

    this.setState({
      modalTitle: 'SPV資料是否正確，確定要創建此資料?',
      modalData: data,
      visible: true,
      values: values
    });
  };

  handleOk = () => {
    this.setState({
      modalTitle: 'SPV創建結果',
      modalData: '正在進行創建，請稍候',
      confirmLoading: true
    });

    axios
      .post(`/api/spv/setup`, {
        name: this.state.values.name,
        domain: this.state.values.domain,
        email: this.state.values.email,
        assetUserId: this.props.id
      }, { headers: { Authorization: getToken() } })
      .then(res => {
        if (res.data.result === 'ok') {
          this.setState({
            modalData: '創建完成',
            confirmLoading: false,
            values: null
          });

          this.checkExisted();
        } else {
          this.setState({
            modalData: sprintf('創建失敗, 錯誤訊息: (%s)', res.data.error),
            confirmLoading: false,
            values: null
          });
        }
      })
      .catch(error => {
        console.error(error);
      });
  };

  handleCancel = () => {
    this.setState({
      visible: false,
      confirmLoading: false,
      values: null
    });
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.showModal(values);
      }
    });
  };

  isAlpha = s => {
    return s.match(/^[a-z]+$/i) !== null;
  };

  checkDomainName = (rule, value, callback) => {
    if (value && !this.isAlpha(value)) {
      callback('域名僅允許由英文字母組成');
    } else if (value.length < 4) {
      callback('至少要四個字母');
    } else {
      callback();
    }
  };

  render() {
    const { getFieldDecorator } = this.props.form;

    const formItemLayout = {
      labelCol: { span: 6 },
      wrapperCol: { span: 14 }
    };

    const tailFormItemLayout = {
      wrapperCol: {
        span: 8,
        offset: 6
      }
    };

    let footer = [];
    if (this.state.values && !this.state.confirmLoading) {
      footer.push(
        <Button key="cancel" onClick={this.handleCancel}>
          取消
        </Button>
      );
      footer.push(
        <Button
          key="ok"
          type="primary"
          loading={this.state.confirmLoading}
          onClick={this.handleOk}
        >
          確認
        </Button>
      );
    } else {
      footer.push(
        <Button
          key="ok"
          type="primary"
          loading={this.state.confirmLoading}
          onClick={this.handleCancel}
        >
          確認
        </Button>
      );
    }
    if (this.state.existed === null) {
      return null;
    } else if (this.state.existed) {
      return (
        <div>
          <div>SPV系統已開通</div>
          <div>名稱: {this.state.data.name}</div>
          <div>域名: {this.state.data.domain}</div>
          <div>帳號: {this.state.data.email}</div>
          <div>--維護功能coming soon!--</div>
        </div>
      );
    } else {
      return (
        <div>
          <Content style={{ margin: '24px 16px 0', overflow: 'initial' }}>
            <Card style={{ width: '100%' }}>
              <Form onSubmit={this.handleSubmit}>
                <FormItem
                  {...formItemLayout}
                  label={
                    <span>
                      名稱&nbsp;
                      <Tooltip title="用於代表SPV的名稱">
                        <Icon type="question-circle-o" />
                      </Tooltip>
                    </span>
                  }
                >
                  {getFieldDecorator('name', {
                    rules: [
                      {
                        required: true,
                        message: '請給SPV一個名稱'
                      }
                    ]
                  })(<Input placeholder="ex.XXX公司" />)}
                </FormItem>
                <FormItem
                  {...formItemLayout}
                  label={
                    <span>
                      URL域名&nbsp;
                      <Tooltip title="用於SPV系統的域名">
                        <Icon type="question-circle-o" />
                      </Tooltip>
                    </span>
                  }
                >
                  {getFieldDecorator('domain', {
                    rules: [
                      {
                        required: true,
                        message: '請給SPV一個域名'
                      },
                      {
                        validator: this.checkDomainName
                      }
                    ]
                  })(<Input placeholder="ex.solafund" />)}
                </FormItem>
                <FormItem
                  {...formItemLayout}
                  label={
                    <span>
                      Email&nbsp;
                      <Tooltip title="用於登入SPV管理的email帳號">
                        <Icon type="question-circle-o" />
                      </Tooltip>
                    </span>
                  }
                >
                  {getFieldDecorator('email', {
                    rules: [
                      {
                        required: true,
                        message: '請輸入email'
                      }
                    ]
                  })(<Input type="email" placeholder="ex.xxxx@mail.com" />)}
                </FormItem>
                <FormItem {...tailFormItemLayout}>
                  <Button type="primary" htmlType="submit">
                    開通SPV系統
                  </Button>
                </FormItem>
              </Form>
              <Modal
                title={this.state.modalTitle}
                visible={this.state.visible}
                onCancel={this.handleCancel}
                footer={footer}
              >
                {this.state.modalData}
              </Modal>
            </Card>
          </Content>
        </div>
      );
    }
  }
}

const WrappedSetupSpvForm = Form.create()(SetupSpvForm);

export default WrappedSetupSpvForm;
